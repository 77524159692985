import React, { useState } from 'react';
import { Tabs, Tab, Card, Form, Row, Col } from 'react-bootstrap';
import '../css/Tabcs.css';  // Custom CSS for the design
import { useDispatch, useSelector } from 'react-redux';
import {updateField } from "../redux/slice/createStrategySlice"
const CustomTabsWithInput = () => {
  const dispatch = useDispatch();
  const {ordertype,run_name} = useSelector((state) => state.programStrategy);
  const [key, setKey] = useState('tab1'); // To track the active tab
  const [inputValue, setInputValue] = useState(''); // State to control input field

  // Handle input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    const { name, value } = e.target;
    dispatch(updateField({ field: name, value }));
  };
  const handleTabSelect = (tab) => {
    dispatch(updateField({ field: 'ordertype', value: tab })); // Set ordertype based on selected tab
  };
  return (
    
    <Card className="p-2 custom-card"> {/* Bootstrap card with padding */}
      {/* Text Input and Label */}
      <Row className="mb-1">
      <Col md={6} className="d-flex">
          <span className="text-element">OrderType :</span> {/* Display input value */}
        </Col>
        <Col md={6} className="d-flex">
          <span className="text-element">Run Name :</span> {/* Display input value */}
        </Col>
      </Row>
      <Row className="mb-3"> {/* Row to align input and label */}
        <Col md={6}>
        <div className="custom-tab-container">
        <Tabs
          id="custom-tabs"
          activeKey={ordertype}
          onSelect={handleTabSelect}
          className="mb-3 custom-tabs"
        >
          {/* Tab 1 */}
          <Tab eventKey="PaperTrading" title="PaperTrading" />

          {/* Tab 2 */}
          <Tab eventKey="LiveTrading" title="LiveTrading" />
          
        </Tabs>
      </div>
        </Col>       
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Enter Run Name"
            name = "run_name"
            value={run_name}          
            onChange={handleInputChange} // Update state on change
          />
        </Col>
        
      </Row>

      {/* Tabs Section */}
      
    </Card>
  );
};

export default CustomTabsWithInput;
