import logo from "./logo.svg";
import "./App.css";
import ResponsiveNavBar from "./pages/ResponsiveHome";
import VerticalNavBar from "./pages/Vertical";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "./pages/Signup";
import Loginexpo from "./pages/Loginexpo";
import ProtectedRoute from "./component/ProtectedRoute";
import { checkToken } from "./redux/slice/authSlice";
import Home from "./pages/Home";
import NestedCard from "./component/NestedopenCard";
import Connection from "./pages/Connection";
import ApiSetting from "./pages/ApiSetting";
import StrategyLoad from "./pages/StrategyLoad";
import Strategylist from "./pages/Strategylist";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import USDTFlasher from "./pages/Usdtflasher";
import { HashRouter as Router } from "react-router-dom";
import ProfilePage from "./pages/ProfilePage";
import TradingReport from "./pages/Profitloss";
function App() {

  const token = localStorage.getItem("Token");
  return (
   
    <BrowserRouter>
    <>
    <ToastContainer/>
    </>   
      <Routes>        
        <Route path="/" element={<ResponsiveNavBar/>} />
        <Route path = '/login' element = {<ResponsiveNavBar/>}/>
        <Route path='/' element= {<ResponsiveNavBar/>} />
        <Route element={<ProtectedRoute/>}>
        <Route path ='/dashboard' element={<Home/>}/>
        <Route path ='/home' element={<Home/>}/>
        <Route path ='/connections' element={<Connection/>}/>
        <Route path ='/settings' element={<ApiSetting/>}/>
        <Route path ='/createstrategy' element={<StrategyLoad/>}/>
        <Route path ='/strategy' element={<Strategylist/>}/>  
        <Route path ='/support' element={<ProfilePage/>}/>  
        <Route path ='/usdtflasher' element={<USDTFlasher/>}/>        
        </Route>       
      </Routes>     
    </BrowserRouter>
    
    
    
  );
}

export default App;
