import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slice/authSlice";
import programStrategyReducer from "../slice/createStrategySlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    programStrategy: programStrategyReducer,
   
  },
  devTools:false,
});

export default store;