
import Login from './Login'
import chart from "../assets/chart.png";
import { Container,Navbar} from "react-bootstrap";
function Loginexpo() {
  return (
    <>      
      <div>
        <Navbar
          bg="light"
          variant="light"
          expand="lg"
          fixed="top"
          className="top-nav box"
        >
          <Container>
            <Navbar href="#home">
              <img
                src={chart} // Replace with your logo URL or path
                width="200"
                height="50"
                className="d-inline-block align-top"
                alt="MyApp Logo"
              />
            </Navbar>
          </Container>
        </Navbar>
      </div>
      <Login/>
    </>
  )
}

export default Loginexpo