import axios from "axios"



 export const RegisterApi = (inputs,phone) =>{
    let data ={username:inputs.name,useremail: inputs.email,password:inputs.password}

    axios.defaults.baseURL = "https://algocranes.com";
    const regsiterurl = "/AlgoRegister";
    return axios.post(regsiterurl, null, { params: {
        username: inputs.name,
        useremail : inputs.email,
        password : inputs.password,
        phone :phone
      }})
}
export async function LoginApi(inputs) {
  axios.defaults.baseURL = "https://algocranes.com";
  const loginurl = "/AlgoLogin";
  return axios.post(loginurl, null, { params: {   
    email : inputs.email,
    password : inputs.password
  }})
}

  
export default {RegisterApi, LoginApi};
