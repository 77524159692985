import React, { useState } from "react";

import { Nav, Navbar, Container, Button, Dropdown } from "react-bootstrap";
import {
  FaHome,
  FaInfinity,
  FaCog,
  FaChartBar,
  FaSignOutAlt,
  FaUserCircle,
} from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import "../css/VerticalNavBar.css"; // Custom CSS for styling
import chart from "../assets/chart.png";
import { useSelector,useDispatch} from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/slice/authSlice";

const VerticalNavBar = () => {
  const [hovered, setHovered] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });
 
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const handlelogout = () => {
    dispatch(logout());
    window.location.reload(); // Refresh to redirect back to login
  };
  return (
    <div>
       <div className="center">
       உலகமெல்லாம் வாழ்க
          </div>
      {/* Vertical Nav for Desktop */}
      <Navbar style={{backgroundColor:"white"}}
        bg= "light"
        variant="light"
        expand="lg"
        fixed="top"
        className="top-nav"
      >
        <Container>
         
          <Navbar.Brand href="/">
            <img
              src={chart} // Replace with your logo URL or path
              width="200"
              height="50"
              className="d-inline-block align-top"
              alt="MyApp Logo"
            />
          </Navbar.Brand>

          {/* Right-aligned login and sign-up buttons */}
          {!isAuthenticated && (
            <div className="d-flex">
              <Link to = '/login' className="custom-login-btn me-2">Login</Link>
            </div>
          )}
          {isAuthenticated && (
            <Nav className="ml-auto">
              <Dropdown align="end">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  <i className="bi bi-person-circle"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handlelogout}>
                    <i className="bi bi-box-arrow-right"></i> Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          )}
        </Container>
      </Navbar>
      {!isMobile && (
        <div className="vertical-navbar ml-3">
          <ul className="nav-links">
            <li>
              <a href="/home">
                <FaHome className="nav-icon" />
                <span className="nav-text">Home</span>
              </a>
            </li>
            <li>
              <a href="/connections">
                <FaInfinity className="nav-icon" />
                <span className="nav-text">Connections</span>
              </a>
            </li>
            <li>
              <a href="/settings">
                <FaCog className="nav-icon" />
                <span className="nav-text">API Settings</span>
              </a>
            </li>
            <li>
              <a href="/strategy">
                <FaChartBar className="nav-icon" />
                <span className="nav-text">Strategy</span>
              </a>
            </li>
            <li>
              <a href="/support">
                <FaUserCircle className="nav-icon" />
                <span className="nav-text">Profile</span>
              </a>
            </li>
            <li>
              <a href="/logout">
                <FaSignOutAlt className="nav-icon" />
                <span className="nav-text">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      )}
      {/* {!isMobile && (        
        <div className="vertical-nav"> 
          
          <Nav className="flex-column text-center my-10">
            <LinkContainer to="/" className="nav-item">
              <Nav.Link
                onMouseEnter={() => setHovered('home')}
                onMouseLeave={() => setHovered(null)}
                className="nav-link-icon"
              >
                <FontAwesomeIcon icon={faHome} size="2x" />
                {hovered === 'home' && <span className="nav-text">Home</span>}
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to="/about" className="nav-item mx-40">
              <Nav.Link
                onMouseEnter={() => setHovered('about')}
                onMouseLeave={() => setHovered(null)}
                className="nav-link-icon"
              >
                <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                {hovered === 'about' && <span className="nav-text">About</span>}
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to="/services" className="nav-item">
              <Nav.Link
                onMouseEnter={() => setHovered('services')}
                onMouseLeave={() => setHovered(null)}
                className="nav-link-icon"
              >
                <FontAwesomeIcon icon={faCogs} size="2x" />
                {hovered === 'services' && <span className="nav-text">Services</span>}
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to="/contact" className="nav-item">
              <Nav.Link
                onMouseEnter={() => setHovered('contact')}
                onMouseLeave={() => setHovered(null)}
                className="nav-link-icon"
              >
                <FontAwesomeIcon icon={faPhone} size="2x" />
                {hovered === 'contact' && <span className="nav-text">Contact</span>}
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </div>
      )}
*/}
      {isMobile && (
        <div className="bottom-nav">
          <Nav>
            <Navbar
              bg="light"
              variant="light"
              fixed="bottom"
              className="bottom-nav d-lg-none"
            >
              <Nav className="w-100 justify-content-around">
                <Nav.Link href="/home" className="text-center">
                  <i className="bi bi-house-fill"></i>
                  <div>Home</div>
                </Nav.Link>
                <Nav.Link href="/connections" className="text-center">
                  <i className="bi bi-ethernet"></i>
                  <div>Connections</div>
                </Nav.Link>
                <Nav.Link href="/strategy" className="text-center">
                  <i className="bi bi-graph-up-arrow"></i>
                  <div>Strategy</div>
                </Nav.Link>
                <Nav.Link href="/settings" className="text-center">
                  <i className="bi bi-gear-fill"></i>
                  <div>API Settings</div>
                </Nav.Link>
                <Nav.Link href="/support" className="text-center">
                  <i className="bi bi-headset"></i>
                  <div>Profile</div>
                </Nav.Link>
              </Nav>
            </Navbar>
          </Nav>
        </div>
      )}
    </div>
  );
};

export default VerticalNavBar;
