import React, { useState } from 'react';
import angel from "../assets/usdt.png";
import { toast } from 'react-toastify';
function USDTFlasher() {
  const [walletPath, setWalletPath] = useState('');
  const [recipientAddress, setRecipientAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [network, setNetwork] = useState('ERC20');

  const handleSendFlash = () => {
    // Logic for sending "Flash" would go here
    if (!walletPath.trim()) {
        toast.error('Please provide a valid wallet path.');
      return;
    }
    if (!recipientAddress.trim()) {
      toast.error('Please enter a valid recipient address.');
      return;
    }    
    if (!amount || parseFloat(amount) <= 0) {
      toast.error('Please enter a valid amount greater than 0.');
      return;
    }
   alert(`Sending ${amount} USDT to ${recipientAddress} on ${network} network`);
    toast.error(' BandWidth Failed Need 103K'); 
  };
  const handleSendWallet= () => {
    // Logic for sending "Flash" would go here
    if (!walletPath.trim()) {
        toast.error('Please provide a valid wallet path.');
      return;
    }
   
   // alert(`Sending ${amount} USDT to ${recipientAddress} on ${network} network`);
    //toast.error(' BandWidth Failed Need 103K'); 
  };

  return (
    <div style={styles.container}>s
      <header style={styles.header}>
        <img src={angel} alt="USDT Logo" style={styles.logo} />
        <h1> USDT Sender</h1>
        <p>Send  USDT into Tether Blockchain</p>
      </header>

      <div style={styles.compatibility}>
        <p>✔️ Binance Compatible</p>       
        <p>✔️ TRX Blockchain Compatible</p>         
        <p>✔️ Swap Programs Compatible</p>
      </div>

      <div style={styles.limitInfo}>
      <h4>Wallet Balance: 500000 USDT</h4>
        <p>Daily limit: 100000 USDT</p>
        <p>Sent today: 0 USDT</p>
        <p>Sent total: 0 USDT</p>
      </div>

      <section style={styles.walletSection}>
        <h3>Load Wallet</h3>
        <p style={styles.warningText}>
          Please don’t buy the wallet from another source than.
          Every wallet is locked on computer MAC address and IP.
        </p>
        <input
          type="text"
          value={walletPath}
          placeholder="C:\\path\\to\\wallet.dat"
          onChange={(e) => setWalletPath(e.target.value)}
          style={styles.input}
        />
        <button onClick={handleSendWallet}style={styles.button}>Open Wallet</button>
        <button onClick={handleSendWallet} style={styles.button}>Save Wallet</button>
      </section>

      <section style={styles.sendSection}>
     
        <h3>Send USDT</h3>
        <div>
          <label>Recipient USDT address:</label>
          <input
            type="text"
            value={recipientAddress}
            onChange={(e) => setRecipientAddress(e.target.value)}
            style={styles.input}
          />
        </div>
        <div>
          <label>Amount USDT:</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            style={styles.input}
          />
        </div>
        <div>
          <label>USDT Network:</label>
          <div style={styles.radioGroup}>
            {['TRC20', 'ERC20'].map((net) => (
              <label key={net} style={styles.radioLabel}>
                <input
                  type="radio"
                  value={net}
                  checked={network === net}
                  onChange={() => setNetwork(net)}
                />
                {net}
              </label>
            ))}
          </div>
        </div>
        <button onClick={handleSendFlash} style={styles.flashButton}>Send TRX USDT!</button>
        <div className="mt-4 text-black">Powered by SRN Technology</div>
      </section>
    </div>
  );
}

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    width: '400px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  logo: {
    width: '50px',
    height: '50px',
  },
  compatibility: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginBottom: '20px',
    color: 'green',
    fontSize: '14px',
  },
  limitInfo: {
    backgroundColor: '#e0f7fa',
    padding: '10px',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  walletSection: {
    marginBottom: '20px',
  },
  warningText: {
    color: 'red',
    fontSize: '12px',
    marginBottom: '10px',
  },
  input: {
    width: '100%',
    padding: '8px',
    margin: '5px 0',
    boxSizing: 'border-box',
  },
  button: {
    marginRight: '10px',
    padding: '8px 12px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  sendSection: {
    marginBottom: '20px',
  },
  radioGroup: {
    display: 'flex',
    gap: '10px',
    margin: '10px 0',
  },
  radioLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  flashButton: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default USDTFlasher;
