import React from 'react'
import CreateNewCard from '../component/CreateNewStrategyCard'
import VerticalNavBar from './Vertical'
import { Container,Row,Col, } from 'react-bootstrap'
import TextCard from '../component/TextCarditem'
import MyTable from '../component/MyTable'
function Strategylist() {
  return (
    <>
    <VerticalNavBar/>
    <Container style={{marginTop:88}}>
        <Row>
        <CreateNewCard/>
        </Row>
        <Row>
        <h5 className='mt-3'>List Of Running Programs</h5>
        </Row>
        <Row>
          <MyTable/>      
        </Row>
        
    </Container>
   
    </>
  )
}

export default Strategylist