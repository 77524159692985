import React from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa'; // Importing the plus icon
import { useNavigate } from 'react-router-dom';
const CreateNewCard = () => {
    const navigate = useNavigate();
    const doHandle = () =>{
        navigate("/createstrategy")
    };
  return (
    <Container className="mt-1">
      <Card className="p-3">
        <div className="d-flex justify-content-end">
          <Button onClick={doHandle} variant="primary" className="d-flex align-items-center">
            <FaPlus className="me-2" /> {/* Plus Icon */}
            Create New
          </Button>
        </div>
      </Card>
    </Container>
  );
};

export default CreateNewCard;
