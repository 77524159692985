import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import navigation hook
const PricingCard = ({ title, price, bgColor, features, onOrderClick }) => {
  return (
    <div className="card text-black mb-3 mx-auto" style={{ maxWidth: "20rem", borderRadius: "15px", backgroundColor: bgColor }}>
      <div className="card-body text-center">
        <h2 className="card-title">{title}</h2>
        <p className="h3 font-weight-bold">₹ {price} <span className="h6 font-weight-normal">/ Month</span></p>
        <p className="small">(Billed Monthly) Including GST</p>
      </div>
      
      <div className="card bg-white text-dark p-3 rounded">
        <h3 className="text-center">What's included</h3>
        <ul className="list-unstyled mt-3">
          {features.map((feature, index) => (
            <li key={index} className="d-flex align-items-center">
              {feature.included ? 
                <span className="text-success fw-bold me-2">✔</span> : 
                <span className="text-danger fw-bold me-2">❌</span>
              }
              {feature.text}
            </li>
          ))}
        </ul>
      </div>
      
      <div className="text-center my-3">
        <button 
          className="btn btn-primary font-weight-bold" 
          onClick={() => onOrderClick(title, price)}
        >
          Order Now ➡️
        </button>
      </div>
    </div>
  );
};

const Pricing = () => {
  const navigate = useNavigate(); // Initialize navigation
  const handleOrderClick = async (title, price) => {
    const token = localStorage.getItem("Token"); // Retrieve JWT token from localStorage

    if (!token) {
      toast.error("You need to log in first!");
      return;
    }

    try {
      const response = await axios.post(
        "https://algocranes.com/Addbills",
        {
          subscriptionid: price, // Use price as subscription ID
          amount: price,
          description: title,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send token in headers
            "Content-Type": "application/json",
          },
        }
      );

      toast.success(`Subscription successful: ${response.data.message}`);
      navigate("/support"); // Navigate to Support page after success
    } catch (error) {
      toast.error("Failed to subscribe. Please try again.");
      console.error(error);
    }
  };
  return (
    <div className="container text-center my-5">
      <h1 className="mb-4">Pricing</h1>
      <div className="row justify-content-center">
        <div className="col-md-4">
          <PricingCard 
            title="Standard" 
            price="369" 
            bgColor="gold" 
            features={ [
              { text: "One month Full Access Platform", included: true },
              { text: "Allowed only in Indian Markets", included: true },
              { text: "5 Live Strategy Deployment", included: true },
              { text: "10 Paper Trading Strategy Allowed", included: true },
              { text: "Unlimited API Binding", included: true },
              { text: "Unlimited TradingView Signals Mapped", included: true },
              { text: "Live Webinar Support", included: false },
              { text: "Whatsapp Personalized Support", included: false },
              { text: "Trade Notifications to Mobile", included: false }
            ]} 
            onOrderClick={handleOrderClick}
         />
        </div>
        <div className="col-md-4">
          <PricingCard 
            title="Premium" 
            price="639" 
            bgColor="gold" 
            features={ [
              { text: "One month Full Access Platform", included: true },
              { text: "Allowed only in Indian Markets", included: true },
              { text: "15 Live Strategy Deployment", included: true },
              { text: "30 Paper Trading Strategy Allowed", included: true },
              { text: "Unlimited API Binding", included: true },
              { text: "Unlimited TradingView Signals Mapped", included: true },
              { text: "2 Live Webinar Support", included: true },
              { text: "Whatsapp Personalized Support", included: true  },
              { text: "Trade Notifications to Mobile", included: false }
            ]}   onOrderClick={handleOrderClick}
          />
        </div>
        <div className="col-md-4">
          <PricingCard 
            title="Elite" 
            price="936" 
            bgColor="gold" 
            features={ [
              { text: "One month Full Access Platform", included: true },
              { text: "Allowed only in Indian Markets", included: true },
              { text: "25 Live Strategy Deployment", included: true },
              { text: "50 Paper Trading Strategy Allowed", included: true },
              { text: "Unlimited API Binding", included: true },
              { text: "Unlimited TradingView Signals Mapped", included: true },
              { text: "4 Live Webinar Support", included: true },
              { text: "Whatsapp Personalized Support", included: true },
              { text: "Trade Notifications to Mobile", included: true }
            ]}   onOrderClick={handleOrderClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
