// src/redux/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { LoginApi } from '../../services/Isauth';
import { toast } from 'react-toastify';
import { startClientConnection, stopSignalRConnection } from '../../services/signalRService';



// Thunk to handle login
export const loginUser = createAsyncThunk('auth/loginUser', async (credentials, thunkAPI) => {
  try {
    const response = await LoginApi(credentials);
    const data1 = response.data;

    if (data1.status) {
      localStorage.setItem("Token", data1.accessToken);
      localStorage.setItem("isauth",true);
      toast.success('Login successful!');      
      startClientConnection(data1.accessToken);
         // Start SignalR connection
    
    //  console.log(localStorage.getItem("Token"));
      return data1;
    } else {
      stopSignalRConnection();
      throw new Error('Login failed');
      
    }
  } catch (error) {
    stopSignalRConnection();
    toast.error('Login failed!');
    localStorage.removeItem("isauth");
    return thunkAPI.rejectWithValue(error.response?.data || { message: 'An error occurred' });
    
  }
});


export const checkToken = createAsyncThunk('auth/checkToken', async (_, thunkAPI) => {
  const token = localStorage.getItem('Token');
  if (!token) {
    stopSignalRConnection();  
    return thunkAPI.rejectWithValue('No token found');
  }

  try {
    axios.defaults.baseURL = "https://algocranes.com";
    const response = await axios.get('/Authenticate', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
     const data2 = await response.data;
     if (data2.status)
     {
      startClientConnection(token);
      localStorage.setItem("isauth",true);      
      console.log(response.data);
      return response.data;
     }
     else{
      localStorage.removeItem("isauth");
      stopSignalRConnection();
      return thunkAPI.rejectWithValue('Token is invalid');   
     }
  } catch (error) {    
    localStorage.removeItem("isauth");
    stopSignalRConnection();
    return thunkAPI.rejectWithValue('Token is invalid');
  }
});


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    message: null,
    accessToken : null,
    isAuthenticated: false,
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.message = null;
      state.accessToken  = null;
      state.isAuthenticated = false;
      localStorage.removeItem("Token");
      localStorage.removeItem("isauth");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        console.log('Login Successful:', action.payload);
        state.message = action.payload.message;
        state.accessToken  = action.payload.accessToken ;
        state.isAuthenticated = true;
        state.loading = false;
        console.log(state.isAuthenticated);
      })
      .addCase(loginUser.rejected, (state, action) => {
        console.log('Login Not Successful:', action.payload);
        state.error = action.payload.message || 'Login failed';
        state.loading = false;
      })
      .addCase(checkToken.pending, (state) => {
        state.loading = true;
        state.isAuthenticated = false;
      })
      .addCase(checkToken.fulfilled, (state, action) => {        
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(checkToken.rejected, (state, action) => {
        state.error = 'Token validation failed';
        state.isAuthenticated = false;
        state.loading = false;
      });
  }
  
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
