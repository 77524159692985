import React, { useState,useEffect } from "react";
import VerticalNavBar from './Vertical'
import '../css/Contain.css'
import '../css/NestedCard.css'
import { Card, Button, Row, Col, Container } from "react-bootstrap";
import { FaCopy } from 'react-icons/fa';
import ApiTable from '../component/Strategytable';
import { toast } from 'react-toastify';
import Createapi from "../services/CreateApi";
import axios from 'axios'; // For making API requests
function Connection() {
  const [sendurl, setSendurl] = useState([]);
  const [receiveurl, setReceiveurl] = useState([]);
  // If loading, show a loader while the token is being validated
  const token = localStorage.getItem('Token');
  useEffect(() => {   
    handlelinkget();  
}, []);
  const handleGenerate= async ()=>{
   
    console.log(token);
    try {
      const response = await Createapi('/GeneratePluginURL',{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); // Replace with your API endpoint
      console.log(response.data);
      // You can handle response data here (e.g., success message)
    
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        toast.error(error.response.data);
      } else {
        // Handle other types of errors
        toast.error('An unexpected error occurred');
      }
    }
  }
  const handlelinkget = async ()=>{
    try {
      const response = await Createapi('/GetUrlLink',{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); // Replace with your API endpoint
      console.log(response.data);
      setSendurl(response.data.senderurl);
      setReceiveurl(response.data.receiverurl);
      // You can handle response data here (e.g., success message)
    
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        toast.error(error.response.data);
      } else {
        // Handle other types of errors
        toast.error('An unexpected error occurred');
      }
    }

  }
  
  return (
   
    <>
    <VerticalNavBar/>    
    <Container style={{marginTop:80}}>   
  
      <Row>
       
    
    <Col>
      {/* Card 1 */}
  
      <Card className="m-2">
        <Card.Body>
          <Card.Title>Signal Sender URL </Card.Title>
          <Card.Text  className="text-orange">
            {sendurl} 
          </Card.Text>
          <Button variant="primary" className="me-2" onClick={handleGenerate}>Generate Link</Button>
          <Button variant="outline-primary" >
            <FaCopy />
          </Button>
        </Card.Body>
      </Card>
   
      {/* Card 2 */}
      <Card className="m-2">
        <Card.Body>
          <Card.Title>Signal Receiver URL </Card.Title>
          <Card.Text className="text-purple">
          {receiveurl}
          </Card.Text>
          <Button variant="primary" className="me-2" onClick={handleGenerate}>Generate Link</Button>
          <Button variant="outline-primary"  >
            <FaCopy />
          </Button>
        </Card.Body>
      </Card>

      
      </Col>
      </Row>
     
      
      <ApiTable/>
    </Container>
    
    </>
 

  )
}

export default Connection