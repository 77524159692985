import React, { useState, useEffect } from "react";
import { Table, Button, Form, Container, Row, Col, Card, Spinner } from "react-bootstrap";
import VerticalNavBar from "./Vertical";
import { FaUserCircle } from "react-icons/fa";
import { FaTimes } from "react-icons/fa"; // Cancel icon
import customer from "../assets/cs.png";
const ProfilePage = () => {
  const [profile, setProfile] = useState({
    name: "",
    userid: "",
    email: "",
    phone: "",
    address: "",
    emailVerified: false,
  });
  const [bills, setBills] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("Token");
      if (!token) {
        console.error("JWT Token not found");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch("https://algocranes.com/getprofile", {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        console.log("API Response:", result);

        if (result.status) {
          setProfile({
            name: result.name || "",
            userid: result.userid || "",
            email: result.email || "",
            phone: result.phone || "",
            address: result.addresss || "",
            emailVerified: result.emailVerified || false,
          });
          
          setBills(result.data || []);
        } else {
          console.error("API returned error:", result.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);  const handleSave = () => {
    console.log("Saved:", profile);
  };

  const handleVerifyEmail = () => {
    console.log("Verify Email Triggered");
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }
  const handlePay = (link) => {
    window.open(link, "_blank");
  };

  const handleCancel = (invoiceRef) => {
    console.log(`Cancel Invoice: ${invoiceRef}`);
    // Implement your cancel logic here, like API call
  };
  return (
    <>
      <VerticalNavBar />
      <Container style={{ marginTop: 80, marginBottom: 100 }}>
        <h2 style={{ textAlign: "center" }}>Profile & Bills</h2>
        <Container>
          <Card className="p-4 shadow-sm">
            <Row>
              <Col md={3} className="text-center">
                <div className="border p-3 mb-3">
                  <img src={customer} alt="Profile"  className="rounded-circle border-stretch"  style={{ width: "100px", height: "100px" }} />
                </div>
                <Button variant="danger">Delete Account</Button>
              </Col>
              <Col md={9}>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            value={profile.name}
                            onChange={(e) => setProfile({ ...profile, name: e.target.value })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>User ID</Form.Label>
                          <Form.Control type="text" value={profile.userid} disabled />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Email</Form.Label>
                          <div className="d-flex">
                            <Form.Control type="email" value={profile.email} disabled={profile.emailVerified} />
                            {!profile.emailVerified && (
                              <Button className="ms-2" onClick={handleVerifyEmail} variant="primary">
                                Verify
                              </Button>
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Phone</Form.Label>
                          <Form.Control type="text" value={profile.phone} disabled />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type="text"
                            value={profile.address}
                            onChange={(e) => setProfile({ ...profile, address: e.target.value })}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button className="mt-3" onClick={handleSave} variant="success">
                      Save
                    </Button>
                  </Form>
                </Col>
            </Row>
          </Card>

          <Card className="mt-4 p-3">
        <h4>Bills & Payments</h4>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Sl</th>
                <th>Invoice Date</th>
                <th>Invoice Ref</th>
                <th>Description</th>
                <th>Invoice Amount</th>
                <th>Pay</th>
                <th>Download</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {bills.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((bill, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{bill.invoicedate}</td>
                  <td>{bill.invoicereferenceno}</td>
                  <td>{bill.description}</td>
                  <td>{bill.invoiceamount}</td>
                  <td>
                    <Button
                      variant="primary"
                      disabled={bill.status !== "Pending"}
                      onClick={() => handlePay(bill.paymentlink)}
                    >
                      Pay
                    </Button>
                  </td>
                  <td>
                    <a
                      href={bill.status === "Paid" ? bill.downloadlink : "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={bill.status !== "Paid" ? "disabled-link" : ""}
                    >
                      <Button variant="secondary" disabled={bill.status !== "Paid"}>
                        Download
                      </Button>
                    </a>
                  </td>
                  <td>{bill.status}</td>
                  <td>
                    {bill.status === "Pending" && (
                      <Button variant="danger" onClick={() => handleCancel(bill.invoicereferenceno)}>
                        <FaTimes />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="d-flex justify-content-between">
          <Button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
            Previous
          </Button>
          <span>Page {currentPage}</span>
          <Button disabled={bills.length / itemsPerPage <= currentPage} onClick={() => setCurrentPage(currentPage + 1)}>
            Next
          </Button>
        </div>
      </Card>
        </Container>
      </Container>
    </>
  );
};

export default ProfilePage;
