import React, { useState, useEffect } from "react";
import { Table, Button, Container , Dropdown, Form ,Modal,Spinner } from "react-bootstrap";
import { FaTimesCircle } from 'react-icons/fa';
import '../css/Strategymodelcs.css';
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "../css/Strategytab.css"; // Import custom styles if any
import CustomModal from "./StrategyModal";

const ApiTable = () => {
  const [data, setData] = useState([]); 
  const [loading, setLoading] = useState(true); // To manage loading state
  const [error, setError] = useState(null);
  const hidde = true;
  // Fetch data from API using Axios
  useEffect(() => {
    const token = localStorage.getItem('Token');
    if (token) {
      setJwtToken(token);
    }
  }, []);

  useEffect(() => {
    fetchStrategies();
  }, []);

  const fetchStrategies = async () => {
    const token = localStorage.getItem("Token");

    if (!token) {
      console.error("JWT Token is missing!");
      toast.error("Authentication failed! Please login again.");
      setError("JWT Token missing");
      setLoading(false);
      return;
    }

    try {
      console.log("Fetching data from API...");
      const response = await axios.get("https://algocranes.com/GetStrategyList", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.status && response.data.statuscode === 200) {
        console.error(response.data.data);
        setData(response.data.data); // 🔥 Extract the `data` array
      } else {
        console.error("Unexpected API response format:", response.data);
        toast.error("Invalid API response format.");
      }
    } catch (error) {
      console.error("Error fetching strategies:", error.response?.data || error.message);
      toast.error("Failed to fetch strategy list.");
      setError(error.response?.data || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select Source");
  const [strategyName, setStrategyName] = useState(""); 
  const [jwtToken, setJwtToken] = useState("");
  // Open & Close Modal
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setSelectedOption("Select Source");
    setStrategyName("");
  };
  // Load JWT token from localStorage on mount
 
  // Handle dropdown selection
  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };

  // Handle strategy name input change
  const handleInputChange = (e) => {
    setStrategyName(e.target.value);
  };
  // Save Strategy
  const handleSave = async () => {
    if (!jwtToken) {
      toast.error("Authentication failed! Please login again.");
      return;
    }

    if (!selectedOption || selectedOption === "Select Source") {
      toast.error("Please select a strategy source!");
      return;
    }
    if (!strategyName.trim()) {
      toast.error("Strategy name cannot be empty!");
      return;
    }

    const payload = {
      strategySource: selectedOption,
      strategyName: strategyName,
    };

    setLoading(true);

    try {
      const response = await axios.post("https://algocranes.com/addNewStrategy", payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (response.status === 200) {
        toast.success("Strategy added successfully!");
        handleClose(); // Close modal
      } else {
        toast.error(response.data.message || "Failed to add strategy.");
      }
    } catch (error) {
      const errorMessage = error.response.data?.message || "Something went wrong!";
      if (error.response?.status === 401) {
        toast.error("Session expired. Please login again.");
        localStorage.removeItem("Token");
        setJwtToken("");
      } else if (error.response.status === 400) {
        toast.error(`Bad Request: ${errorMessage}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (id) => {
    const updatedData = data.filter((user) => user.id !== id);
    setData(updatedData);
  };
  // Handle dropdown selection
 
  return (
    <Container>
     <div className="table-container">
        {/* Create New Button */}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5>StrategyName List</h5>
          <Button onClick={handleShow} variant="primary">
            Create New StrategyName & Params
          </Button>

          {/* Modal */}
          <Modal show={show} onHide={handleClose} centered>
            {/* Modal Header */}
            <Modal.Header className="d-flex justify-content-between align-items-center">
              <Modal.Title>Choose Strategy Source</Modal.Title>
              <Button variant="light" className="p-0" onClick={handleClose}>
                <FaTimesCircle size={24} color="red" />
              </Button>
            </Modal.Header>

            {/* Modal Body */}
            <Modal.Body>
              {/* Dropdown */}
              <Dropdown onSelect={handleSelect} className="mb-3">
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                {selectedOption}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="TV">TradingView</Dropdown.Item>
                  <Dropdown.Item eventKey="MT5">MT5</Dropdown.Item>
                  <Dropdown.Item eventKey="MT4">MT4</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {/* Text Input */}
              <Form.Group controlId="formBasicText">
                <Form.Label>Enter Strategy Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Strategy Name"
                  value={strategyName}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Modal.Body>

            {/* Modal Footer */}
            <Modal.Footer className="d-flex justify-content-end">
              <Button variant="primary" onClick={handleSave} disabled={loading}>
                {loading ? <Spinner as="span" animation="border" size="sm" /> : "Save"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      

        {/* Table */}
        <>
      {loading ? (
        <div className="text-center mt-3">
          <Spinner animation="border" variant="primary" />
          <p>Loading strategies...</p>
        </div>
      ) : (
        <div className="table-responsive">
        <Table striped bordered hover>
          <thead className="table-header">
            <tr>             
              <th>strategyID</th>
              <th>Strategy Source</th>
              <th>Strategy Name</th>
              <th>Buy Params</th>
              <th>Sell Params</th>
              <th>expiryAt</th>
              <th>isLive</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((strategy) => (
                <tr key={strategy.numericId}>                  
                  <td>{strategy.strategyID}</td>
                  <td>{strategy.strategysource}</td>
                  <td>{strategy.strategyname}</td>
                  <td>{strategy.buyparams}</td>
                  <td>{strategy.sellparams}</td>
                  <td>{strategy.expiryAt}</td>
                  <td>{strategy.isLive}</td>
                  <td>
                    <Button variant="primary">🗑 Delete</Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No strategies found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        </div>
      )}
    </>
      </div>
    </Container>
  );
};

export default ApiTable;
