import { createSlice } from '@reduxjs/toolkit';

// Define the initial state for program strategy
const initialState = {  
  algo_on: 'OFF',
  delete_on: 'false',
  userid: '',
  plugin_id: '',
  run_name: '',
  ordertype: 'PaperTrading',
  terminal_source: '',
  segment: '',
  symbols: '',
  strategy_source: '',
  entry_when: "Not Selected",
  exit_when: "Not Selected",
  op_segment: "Futures",
  op_expiry : "Current Week",
  op_premiumselector : "Spot Based",
  op_strike: "ITM1",
  op_strikeprice: "",
  op_optiontype: "CE",
  op_qty: "1",
  op_tradetype: "BUY",
  op_slmode: "Pts",
  op_stoploss: "0",
  op_tpmode: "Pts",
  op_target: "0", 
  intra_pos: '',
  expiry_handle: '',
  daily_target: '',
  daily_loss: '',
  max_per_day: '',
  updated_at: '',
};

// Create the slice
const programStrategySlice = createSlice({
  name: 'programStrategy',
  initialState,
  reducers: {
    setProgramStrategy: (state, action) => {
      // Update the entire program strategy state
      return { ...state, ...action.payload };
    },
    updateField: (state, action) => {
      // Generic function to update a field in the program strategy
      const { field, value } = action.payload;
      state[field] = value;
    },
    updateSegment: (state, action) => {
      // Generic function to update a field in the program strategy
      state.op_segment = action.payload;
    },
    updateExpiry: (state, action) => {
      // Generic function to update a field in the program strategy
      state.op_expiry = action.payload;
    },
    updatePremiumSelector: (state, action) => {
      // Generic function to update a field in the program strategy
      state.op_premiumselector = action.payload;
    },
    updateStrike: (state, action) => {
      // Generic function to update a field in the program strategy
      state.op_strike = action.payload;
    },
    updateStrikePrice: (state, action) => {
      // Generic function to update a field in the program strategy
      state.op_strikeprice = action.payload;
    },
    updateOptionType: (state, action) => {
      // Generic function to update a field in the program strategy
      state.op_optiontype = action.payload;
    },
    updateQty: (state, action) => {
      // Generic function to update a field in the program strategy
      state.op_qty = action.payload;
    },
    updateTradeType: (state, action) => {
      // Generic function to update a field in the program strategy
      state.op_tradetype = action.payload;
    },
    updateSLMode: (state, action) => {
      // Generic function to update a field in the program strategy
      state.op_slmode = action.payload;
    },
    updateStopLoss: (state, action) => {
      // Generic function to update a field in the program strategy
      state.op_stoploss = action.payload;
    },
    updateTPMode: (state, action) => {
      // Generic function to update a field in the program strategy
      state.op_tpmode = action.payload;
    },
    updateTarget: (state, action) => {
      // Generic function to update a field in the program strategy
      state.op_target = action.payload;
    },
    setEntryWhen: (state, action) => {
      state.entry_when = action.payload;
    },
    setExitWhen: (state, action) => {
      state.exit_when = action.payload;
    },
    setIntraPos: (state, action) => {
      state.intra_pos = action.payload;
    },
    setDailyLoss: (state, action) => {
      state.daily_loss = action.payload;
    },
    setDailyTarget: (state, action) => {
      state.daily_target = action.payload;
    },
  }
});

// Export actions and reducer
export const { setProgramStrategy,setIntraPos,setDailyLoss,setDailyTarget,setEntryWhen,setExitWhen,updateField,updateSegment,updateExpiry,updatePremiumSelector,updateStrike,updateStrikePrice,updateOptionType,updateQty,updateTradeType,updateSLMode,updateStopLoss,updateTPMode,updateTarget} = programStrategySlice.actions;
export default programStrategySlice.reducer;
