import React, { useState } from "react";
import VerticalNavBar from "./Vertical";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import angel from "../assets/angel.png";
import upstox from "../assets/upstox.png";
import axios from 'axios';
import Createapi from "../services/CreateApi";
function ApiSetting() {
  const [show, setShow] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [brokername, setBrokerName] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [additionalkey_1, setAdditionalKey] = useState("");
  const [additionalkey_2, setAdditionalKey2] = useState("");
  const [additionalkey_3, setAdditionalKey3] = useState("");
  const [error, setError] = useState(null);

  const handleClose = () => {setAdditionalKey("");setApiKey("");setSecretKey("");setBrokerName("");setShow(false)};
  const handleShow = () => {setBrokerName("AngelBroking");setShow(true);}
  const token = localStorage.getItem('Token');
  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const data = {
      brokername,
      apiKey,
      secretKey,
      additionalkey_1,
      additionalkey_2,
      additionalkey_3,
    };

    try {
      const response = await Createapi.post("/addTerminal", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); // Replace with your API endpoint
      console.log(response.data);
      // You can handle response data here (e.g., success message)
      handleClose(); // Close modal on successful submission
    } catch (err) {
      console.log(err);
      setError("Failed to submit data. Please try again."); // Handle error appropriately
    }
  };
  return (
    <>
      <VerticalNavBar />
      <Container style={{ marginTop: 85 }}>
        <Row id="features" className="my-5">
          <Col md={2}>
            <Card>
              <Card.Img variant="top" src={angel} />
              <Card.Body>
                <Card.Title>AngelBroking</Card.Title>
                <Button variant="primary" onClick={handleShow}>
                  Configure
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2}>
            <Card>
              <Card.Img variant="top" src={upstox} />
              <Card.Body>
                <Card.Title>Upstox Broking</Card.Title>
                <Button variant="primary">Configure</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>API Key Input</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="apiKey" className="form-label">
                API KEY:
              </label>
              <input
                type="text"
                className="form-control"
                id="apiKey"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)} // Update state on input change
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="secretKey" className="form-label">
                Secretkey:
              </label>
              <input
                type="text"
                className="form-control"
                id="secretKey"
                value={secretKey}
                onChange={(e) => setSecretKey(e.target.value)} // Update state on input change
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="totpCode" className="form-label">
                TOTP Code:
              </label>
              <input
                type="text"
                className="form-control"
                id="totpCode"
                value={additionalkey_1}
                onChange={(e) => setAdditionalKey(e.target.value)} // Update state on input change
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="additional2" className="form-label">
                AdditionalKey_2:
              </label>
              <input
                type="text"
                className="form-control"
                id="additional2"
                value={additionalkey_2}
                onChange={(e) => setAdditionalKey2(e.target.value)} // Update state on input change
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="additional3" className="form-label">
                AdditionalKey_3:
              </label>
              <input
                type="text"
                className="form-control"
                id="additional3"
                value={additionalkey_3}
                onChange={(e) => setAdditionalKey3(e.target.value)} // Update state on input change
                required
              />
            </div>
            {error && <p className="text-danger">{error}</p>}{" "}
            {/* Display error message */}
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ApiSetting;
