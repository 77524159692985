import * as signalR from "@microsoft/signalr";

let connection = null;

export const startClientConnection = (token) => {
  connection = new signalR.HubConnectionBuilder()
    .withUrl("https://localhost:7229/ChatHub", {
      accessTokenFactory: () => token,
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    })
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connection.start()
    .then(() => {
      console.log(`Connected to SignalR as a client: ${connection.ConnectionId}`);
    })
    .catch((err) => console.error("SignalR connection error: ", err));

  return connection;
};
export const stopSignalRConnection = () => {
  if (connection) {
    connection.stop().then(() => console.log("SignalR Disconnected."));
  }
}
// Client sends message to support team
export const sendClientMessageToSupport = async (clientid,message) => {
  if (connection && connection.state === signalR.HubConnectionState.Connected) {
    try {
      await connection.invoke("SendMessageToSupportTeam", clientid, message, "ChartVibes"); // ChartVibes is the group name
      console.log("Message sent to support team");
    } catch (error) {
      console.error("Error sending message to support team:", error);
    }
  } else {
    console.warn("SignalR connection is not established.");
  }
};

// Receive support team's message
export const addClientMessageListener = () => {
  if (connection) {
    connection.on("ReceiveSupportMessage", (message) => {
      console.log("Received message from support team:", message);
      // Handle message display in UI or Redux
    });

    connection.on("ReceiveClientMessage", (clientId, message) => {
      console.log(`Message from client ${clientId}: ${message}`);
    });

    connection.on("GroupAdded", (groupName) => {
      console.log(`Added to group: ${groupName}`);
      // You can handle the UI update or state here, e.g., notify the user they've been added
    });
  }
};
export const assignClientToGroup = async (groupName) => {
  if (connection && connection.state === signalR.HubConnectionState.Connected) {
    try {
      await connection.send('AssignClientToGroup', groupName);
      console.log(`Client assigned to group: ${groupName}`);
    } catch (error) {
      console.error('Error assigning to group:', error);
    }
  } else {
    console.warn('SignalR connection is not established.');
  }
};
