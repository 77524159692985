import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  Button,
  Container,
  Row,
  Col,
  Card,
  Dropdown,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import chart from "../assets/chart.png";
import { Modal, Form,Spinner } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../redux/slice/authSlice";
import { addSignalRListener } from "../services/signalRService";
import { checkToken } from "../redux/slice/authSlice";
import USDTFlasher from "./Usdtflasher";

//import "../css/lux.css"
import "../css/Customdrop.css";
import {
  FaFacebook,
  FaTwitter,
  FaTelegram,
  FaYoutube,
  FaEnvelope,
} from "react-icons/fa";

import { FaPlay, FaClipboardCheck, FaRocket } from "react-icons/fa"; // Icons
import Pricing from "./Pricing";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firebase/Setup"; // ✅ Ensure correct import
import { RegisterApi } from "../services/Isauth";
import { toast } from "react-toastify";
import axios from "axios";
import { loginUser } from "../redux/slice/authSlice";
import LeaderCard from "./LeaderBoard";

const ResponsiveNavBar = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [showModal, setShowModal] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmation, setConfirmation] = useState(null);
  const [otpVerified, setOtpVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const SigninhandleShow = () => setShowSignIn(true);
  const SigninhandleClose = () => setShowSignIn(false);
  const [signInInputs, setSignInInputs] = useState({ email: "", password: "" });

  // Handle input changes


  const handleInput = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const sendOTP = async () => {
    if (!phone || phone.length < 10) {
      alert("Enter a valid phone number.");
      return;
    }

    try {
      const appVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {}
      );
      const confirmation = await signInWithPhoneNumber(
        auth,
        `+${phone}`,
        appVerifier
      );
      console.log(confirmation);
      setConfirmation(confirmation);
      console.log("OTP sent successfully!");
    } catch (error) {
      console.error("Error sending OTP:", error.message);
      alert("Failed to send OTP: " + error.message);
    }
  };

  const verifyOTP = async () => {
    if (!confirmation || !otp) return alert("Enter OTP first!");

    try {
      await confirmation.confirm(otp);
      setOtpVerified(true);
      alert("OTP Verified! You can now enter your details.");
    } catch (error) {
      alert("Invalid OTP. Try again.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!inputs.name || !inputs.email || !inputs.password) {
      alert("Please fill all fields.");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(
        "https://algocranes.com/AlgoRegister",
        null,
        {
          params: {
            username: inputs.name,
            useremail: inputs.email,
            password: inputs.password,
            phone: phone,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Registeration was Successful!");
        setLoading(false);
        handleClose(); // Close modal
      } else {
        toast.error(response.data.message || "Please try again.");
      }
    } catch (error) {
      const errorMessage = error.response.data?.message || "Please try again.";
      if (error.response?.status === 401) {
        toast.error("Please try again.");
        setLoading(false);
      } else if (error.response.status === 400) {
        toast.error(`${errorMessage}`);
        setLoading(false);
      }
    } finally {
      setLoading(false);
     
    }
  };
  const handleSignInInput = (e) => {
    setSignInInputs({ ...signInInputs, [e.target.name]: e.target.value });
  };
 {/* const dohandleLogin = () => {
    navigate("/login");
  };
  const dohandlesignup = () => {
    navigate("/signup");
  }; */}
  const handleSubmitSignIn = async (event) => {
    
    setLoading(true);
      event.preventDefault();
        {
          //console.log(values);
          setLoading(true);
    
        dispatch(loginUser(signInInputs));
          
    
        }
     
    setLoading(false);
    setShowSignIn(false);
       
  };
  useEffect(() => {
    if (!isAuthenticated) {
      // Dispatch the checkToken action to validate the token
      dispatch(checkToken()).finally(() => {});
    } else {
      // If no access token is found, consider it initialized
    }
  }, [dispatch, isAuthenticated]);

  const handlelogout = () => {
    dispatch(logout());
    navigate("/")
    window.location.reload(); // Refresh to redirect back to login
  };
  const steps = [
    {
      id: 1,
      title: "Step 1: Get Started",
      description: "Sign up and create your account to access all features.",
      icon: <FaPlay size={40} className="step-icon" />,
    },
    {
      id: 2,
      title: "Step 2: Choose a Plan",
      description: "Select a plan that best fits your needs and preferences. ",
      icon: <FaClipboardCheck size={40} className="step-icon" />,
    },
    {
      id: 3,
      title: "Step 3: Launch",
      description: "Start using our services and achieve your goals!",
      icon: <FaRocket size={40} className="step-icon" />,
    },
  ];

  const [sticky, setSticky] = useState(false);
 
  const requestOTPRead = async () => {
    if ("OTPCredential" in window) {
      try {
        const otp = await navigator.credentials.get({ otp: { transport: ["sms"] } });
        setOtp(otp.code); // Auto-fill the OTP field
      } catch (error) {
        console.error("Error reading OTP:", error);
      }
    }
  };
  useEffect(() => {
    requestOTPRead ();
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div style={{ backgroundColor: "white", color: "black" }}>
      {/* Sticky Navbar */}

      <nav
        className={`navbar navbar-expand-lg  navbar-light ${
          sticky ? "sticky-top shadow" : ""
        }`}
        style={{ backgroundColor: "#FFD700" }}
      >
        <div className="container">
          <a
            className="navbar-brand fw-bold"
            href="#home"
            style={{ color: "black", fontSize: "1.5rem" }}
          >
            <img
              src={chart} // Replace with your logo URL or path
              width="200"
              height="50"
              className="d-inline-block align-top"
              alt="MyApp Logo"
            />
          </a>
          {!isAuthenticated && (
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#solutions"
                    style={{ color: "black" }}
                  >
                    Solutions
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#why-choose-us"
                    style={{ color: "black" }}
                  >
                    Why Choose Us
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#footer"
                    style={{ color: "black" }}
                  >
                    LeaderBoard
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#pricing"
                    style={{ color: "black" }}
                  >
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#footer"
                    style={{ color: "black" }}
                  >
                    ContactUs
                  </a>
                </li>
                <li>
                  <button
                    className="nav-link btn btn-dark ms-3 custom-btn"
                    onClick={SigninhandleShow}
                  >
                    Sign In
                  </button>
                </li>
                <li>
                  <button
                    className="nav-link btn btn-outline-dark ms-2 custom-btn"
                    onClick={handleShow}
                  >
                    Sign Up
                  </button>
                </li>
              </ul>
            </div>
          )}
          {isMobile && (
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <button
                    className="nav-link btn btn-dark ms-3 custom-btn"
                    onClick={SigninhandleShow}
                  >
                    Sign In
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link btn btn-outline-dark ms-2 custom-btn"
                    onClick={handleShow}
                  >
                    Sign Up
                  </button>
                </li>
              </ul>
            </div>
          )}
          {isAuthenticated && (
            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#solutions"
                    style={{ color: "black" }}
                  >
                    Solutions
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#why-choose-us"
                    style={{ color: "black" }}
                  >
                    Why Choose Us
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#pricing"
                    style={{ color: "black" }}
                  >
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#footer"
                    style={{ color: "black" }}
                  >
                    LeaderBoard
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#footer"
                    style={{ color: "black" }}
                  >
                    ContactUs
                  </a>
                </li>
              </ul>
            </div>
          )}
          {isAuthenticated && (
            <Nav className="ml-auto">
              <Dropdown align="end">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  <i className="bi bi-person-circle"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => navigate("/dashboard")}>
                    <i className="bi bi-person"></i> DashBoard
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handlelogout}>
                    <i className="bi bi-box-arrow-right"></i> LogOut
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          )}
        </div>
      </nav>
      <style>
        {`
        .navbar-nav .nav-item .nav-link:hover {
          color: white !important;
        }
      `}
      </style>

      {/* Hero Section */}
      <header className="d-flex align-items-center justify-content-center text-center vh-100">
        <div>
          <h1 className="display-1 fw-bold">
            Build, Optimize, Dominate <br />
            The AI Powered Algo Trading Way!
          </h1>
          <p className="lead fs-4">
            {" "}
            Signals. Execution. Profit – AI Does It All!
          </p>
          <div className="mt-4">
            <button className="btn btn-dark btn-lg me-3 custom-btn">
              Expore Now
            </button>
          </div>
        </div>
      </header>

      {/* Solutions Section */}
      <section id="solutions">
        <div className="container">
          <h2 className="text-center display-3 fw-bold">
            Our Cutting-Edge Solutions
          </h2>
          <p className="text-center">
            Discover how we revolutionize industries with advanced technologies.
          </p>
          <div className="row mt-4 g-4">
            <div className="col-lg-6">
              <div
                className="card text-center p-4 shadow"
                style={{
                  backgroundColor: "gold",
                  color: "black",
                  borderRadius: "15px",
                }}
              >
                <h4 className="fw-bold">
                  <i className="bi bi-bar-chart me-2"></i> AI Analytics
                </h4>
                <p>Why Use AI Analytics in TradingView using ChartVibes?</p>
                <p>
                  ✅ Enhances Trading Efficiency – AI cuts through market noise
                  and provides real-time, actionable insights{" "}
                </p>
                <p>
                  ✅ Reduces Human Errors – AI eliminates emotional
                  decision-making and optimizes risk management{" "}
                </p>
                <p>
                  ✅ Customizable & Scalable – AI analytics adapts to different
                  trading styles, from scalping to long-term investing
                </p>
                <p>
                  ✅ Maximizes Profit Potential – AI identifies hidden
                  opportunities that manual traders might miss
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="card text-center p-4 shadow"
                style={{
                  backgroundColor: "gold",
                  color: "black",
                  borderRadius: "15px",
                }}
              >
                <h4 className="fw-bold">
                  <i className="bi bi-cloud me-2"></i> Cloud Solutions
                </h4>
                <p>🚀 Trade Smarter with Cloud-Based API Execution! ☁️⚡</p>
                <p>
                  ✅ Real-Time Order Placement – Instantly execute trades with
                  minimal latency.
                </p>
                <p>
                  ✅ Scalable & Reliable – Handle high-frequency trading with
                  cloud infrastructure.
                </p>
                <p>
                  ✅ AI-Driven Automation – Integrate intelligent trading bots
                  for precision execution.
                </p>
                <p>
                  ✅ Secure & Fast – Encrypted data transfer ensures safe
                  transactions.
                </p>
                <p>
                  ✅ 24/7 Availability – Execute trades anytime, anywhere,
                  without downtime.
                </p>
                <p>
                  🔹 Optimize your trading with powerful cloud solutions today!
                  📈🔥
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="card text-center p-4 shadow"
                style={{
                  backgroundColor: "gold",
                  color: "black",
                  borderRadius: "15px",
                  height: "420px",
                }}
              >
                <h4 className="fw-bold">
                  <i className="bi bi-shield-lock me-2"></i> Security
                </h4>
                <p>
                  ✅ Secure API Access – Only authenticated users with valid JWT
                  tokens can execute trades.
                </p>
                <p>
                  ✅ Token-Based Authorization – Every trade request is verified
                  with JWT to prevent unauthorized access.
                </p>
                <p>
                  ✅ End-to-End Encryption – Ensures all data transmissions
                  between users and the algo trading API remain encrypted.
                </p>
                <p>
                  ✅ Session Management – Secure token expiration and renewal
                  mechanisms to maintain session integrity.
                </p>
                <p>
                  ✅ DDoS & Unauthorized Access Protection – AI-driven
                  monitoring ensures real-time threat detection.
                </p>
                <p>
                  🔹 Trade confidently, knowing your data and transactions are
                  protected! 🚀
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="card text-center p-4 shadow"
                style={{
                  backgroundColor: "gold",
                  color: "black",
                  borderRadius: "15px",
                  height: "420px",
                }}
              >
                <h4 className="fw-bold">
                  <i className="bi bi-speedometer me-2"></i> High Performance
                </h4>
                <p>
                  ✅ Access Proven Strategies – Track and adopt strategies from
                  top traders.
                </p>
                <p>
                  ✅ Seamless API Execution – Connect strategies to your trading
                  account with a few clicks.
                </p>
                <p>
                  ✅ Real-Time Performance Metrics – Monitor leaderboard updates
                  for the best-performing setups.
                </p>
                <p>
                  ✅ Secure & Automated – Execute trades with precision, backed
                  by powerful algo trading.
                </p>
                <p>
                  ✅ Customizable & Scalable – Adjust strategies to fit your
                  risk and trading style.
                </p>
                <p>
                  🔹 Leverage the best strategies, automate your trades, and
                  stay ahead in the market! 🚀📈
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div>
        {/* Modal */}

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Sign Up</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* Phone Number Input */}
            <div>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <div className="d-flex align-items-center">
                  <PhoneInput
                    country={"in"}
                    value={phone}
                    onChange={setPhone}
                    inputStyle={{
                      width: "100%",
                      paddingLeft: "50px",
                      height: "38px",
                    }}
                    containerStyle={{ width: "100%" }}
                  />
                  <Button
                    variant="info"
                    className="ms-2"
                    style={{ whiteSpace: "nowrap", height: "38px" }}
                    onClick={sendOTP}
                  >
                    Send OTP
                  </Button>
                </div>
              </Form.Group>
            </div>
            <div id="recaptcha-container"></div>
            {/* OTP Verification */}
            <Form.Group className="mb-3">
              <Form.Label>Enter OTP</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <Button
                  variant="success"
                  className="ms-2"
                  style={{ whiteSpace: "nowrap", height: "38px" }}
                  onClick={verifyOTP}
                >
                  Verify OTP
                </Button>
              </div>
            </Form.Group>

            {/* Recaptcha Container */}

            {/* User Details (Disabled until OTP is Verified) */}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  name="name"
                  value={inputs.name}
                  onChange={handleInput}
                  disabled={!otpVerified}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  value={inputs.email}
                  onChange={handleInput}
                  disabled={!otpVerified}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  name="password"
                  value={inputs.password}
                  onChange={handleInput}
                  disabled={!otpVerified}
                />
              </Form.Group>

              <Button
                type="submit"
                variant="primary"
                disabled={!otpVerified || loading}
              >
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-2" />{" "}
                    Submitting...
                  </>
                ) : (
                  "Register"
                )}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
          {/* Sign In Modal */}
      <Modal show={showSignIn} onHide={() => setShowSignIn(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Sign In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitSignIn}>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter Email" name="email" value={signInInputs.email} onChange={handleSignInInput} required />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Enter Password" name="password" value={signInInputs.password} onChange={handleSignInInput} required />
            </Form.Group>

            <Button type="submit" variant="success" disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" className="me-2" /> : "Sign In"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      </div>

      {/* Why Choose Us Section */}
      <section id="why-choose-us" className="py-5 bg-light">
        <div className="container">
          <h2 className="text-center fw-bold">Why Choose Us?</h2>
          <p className="text-center">
            We bring innovation, expertise, and reliability to the table.
          </p>
          <div className="row mt-4">
            {[
              {
                icon: "bi-award",
                title: "Proven Expertise",
                desc: (
                  <>
                    ✅ <b>Proven Expertise</b> – Built by market professionals
                    with deep trading & AI knowledge. <br />✅{" "}
                    <b>Secure & Reliable</b> – Advanced JWT authentication &
                    encrypted transactions. <br />✅{" "}
                    <b>High-Performance Execution</b> – Ultra-low latency for
                    fast & accurate trades. <br />✅{" "}
                    <b>AI-Powered Strategies</b> – Intelligent algorithms to
                    maximize profit potential. <br />✅{" "}
                    <b>Leaderboard Integration</b> – Access and copy
                    top-performing strategies effortlessly. <br />✅{" "}
                    <b>Scalable & Customizable</b> – Tailored trading solutions
                    for every trader’s needs. <br />
                    🔹 <b>Trade smarter, safer, and faster</b> with our next-gen
                    algo trading solutions!
                  </>
                ),
              },
              {
                icon: "bi-lightbulb",
                title: "Innovative Approach",
                desc: (
                  <>
                    ✅ <b>AI-Powered Trading</b> – Machine learning-driven
                    strategies for smarter decision-making. <br />✅{" "}
                    <b>Cloud-Based Execution</b> – Scalable, high-speed order
                    placement with minimal latency. <br />✅{" "}
                    <b>Leaderboard Integration</b> – Mirror top-performing
                    strategies seamlessly. <br />✅ <b>Data-Driven Insights</b>{" "}
                    – Advanced analytics for trend detection and market
                    prediction. <br />✅ <b>Adaptive Risk Management</b> – Smart
                    algorithms optimize trades for maximum efficiency. <br />
                    🔥 <b>Seamless execution, high-speed processing</b> 🔥{" "}
                    <br />
                    🔹 <b>Stay ahead of the market</b> with innovation at your
                    fingertips! 📈🔥
                  </>
                ),
              },
              {
                icon: "bi-people",
                title: "Client-Centric",
                desc: (
                  <>
                    ✅ <b>Tailored Solutions</b> – Customizable trading
                    strategies to match your goals. <br />✅ <b>24/7 Support</b>{" "}
                    – Dedicated assistance to ensure a seamless trading
                    experience. <br />✅ <b>User-Friendly Interface</b> –
                    Intuitive and easy-to-use platform for all traders. <br />✅{" "}
                    <b>Continuous Improvement</b> – We evolve based on client
                    feedback & market trends. <br />✅{" "}
                    <b>Transparency & Trust</b> – Secure, fair, and reliable
                    trading infrastructure. <br />
                    🔹 <b>Your success is our priority</b> – trade with
                    confidence, trade with us! 🚀📈
                  </>
                ),
              },
              {
                icon: "bi-graph-up",
                title: "Scalability",
                desc: (
                  <>
                    ✅ <b>Cloud-Based Architecture</b> – Handle high trade
                    volumes with ultra-low latency. <br />✅{" "}
                    <b>Flexible API Integration</b> – Adaptable to various
                    trading strategies and platforms. <br />
                    🔥 <b>Seamless execution, high-speed processing</b> 🔥{" "}
                    <br />✅ <b>Multi-Account Support</b> – Manage multiple
                    accounts effortlessly with automation. <br />✅{" "}
                    <b>Real-Time Data Processing</b> – Scale efficiently without
                    compromising speed or accuracy. <br />✅{" "}
                    <b>Enterprise-Grade Performance</b> – Built for retail
                    traders, hedge funds, and institutions. <br />
                    🔹 <b>Trade without limits</b> – scale your strategy,
                    maximize your potential! 📈🔥
                  </>
                ),
              },
            ].map((item, index) => (
              <div key={index} className="col-md-6 col-lg-3 mb-4">
                <div
                  className="card text-center p-4 shadow"
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    borderRadius: "15px",
                  }}
                >
                  <h4 className="fw-bold">
                    <i className={`bi ${item.icon} me-2`}></i> {item.title}
                  </h4>
                  <p className="text-start fs-5">{item.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/*Pricing */}
      <section id="pricing">
        <Pricing />    
      </section>
      {/* Footer */}
      <footer
        section
        id="footer"
        className="bg-dark text-white text-center py-3"
        style={{ marginTop: "auto" }}
      >
        <Container>
          <p className="mb-0">&copy; 2025 All rights reserved</p>
          <p className="display-8">
            Nile Bazzar Enterprises, 71, 9th Street, Vadavalli, Bharathiyar
            University (PO), Coimbatore, Tamil Nadu-6410046
          </p>

          <p>
            <strong>Support:</strong> Tamil, English:{" "}
            <a href="tel:8838277821" className="text-white">
              8838277821
            </a>{" "}
            |
            {/* Hindi: <a href="tel:6374921238" className="text-white">6374921238</a>, <a href="tel:7305977320" className="text-white">7305977320</a>*/}
          </p>
          <p>
            <strong>Email:</strong>{" "}
            <a href="mailto:info@chartvibes.com" className="text-white">
              info@chartvibes.com
            </a>
          </p>
          <p>
            <strong>Business Partnership & Bulk Sales:</strong>{" "}
            <a href="tel:6374921238" className="text-white">
              6374921238
            </a>
          </p>

          {/* Social Media Links */}
          <div className="d-flex justify-content-center gap-3 my-3">
            <a
              href="https://facebook.com"
              className="text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook size={28} />
            </a>
            <a
              href="https://twitter.com"
              className="text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter size={28} />
            </a>
            <a
              href="https://t.me"
              className="text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTelegram size={28} />
            </a>
            <a
              href="https://youtube.com"
              className="text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube size={28} />
            </a>
            <a href="mailto:contactus@quantman.in" className="text-white">
              <FaEnvelope size={28} />
            </a>
          </div>

          {/* Additional Links */}
          <div>
            <a href="/terms" className="text-white mx-2">
              Terms & Conditions
            </a>{" "}
            |
            <a href="/faq" className="text-white mx-2">
              FAQ
            </a>{" "}
            |
            <a href="/policy" className="text-white mx-2">
              Privacy Policy
            </a>{" "}
            |
            <a href="/about" className="text-white mx-2">
              About Us
            </a>{" "}
            |
            <a href="/best-algo" className="text-white mx-2">
              Return Policy
            </a>{" "}
            |
            <a href="/top-algo-platforms" className="text-white mx-2">
              Shipping Policy
            </a>{" "}
            |
            <a href="/blogs" className="text-white mx-2">
              Blogs
            </a>
          </div>
        </Container>
      </footer>
      {/* Bottom Navigation for Mobile */}
      <Navbar
        bg="light"
        variant="light"
        fixed="bottom"
        className="bottom-nav d-lg-none box"
      >
        <Nav className="w-100 justify-content-around">
          <Nav.Link href="#solutions" className="text-center">
            <i className="bi bi-house-fill"></i>
            <div>Home</div>
          </Nav.Link>
          <Nav.Link href="#why-choose-us" className="text-center">
            <i className="bi bi-stars"></i>
            <div>Why Choose Us</div>
          </Nav.Link>
          <Nav.Link href="#pricing" className="text-center">
            <i className="bi bi-tags-fill"></i>
            <div>Pricing</div>
          </Nav.Link>
          <Nav.Link  onClick={SigninhandleShow} className="text-center">
          <i class="bi bi-box-arrow-in-left"></i>
            <div>SignIn</div>
          </Nav.Link>
          <Nav.Link  onClick={handleShow} className="text-center">
          <i class="bi bi-door-open"></i>
            <div>SignUp</div>
          </Nav.Link>
        </Nav>
      </Navbar>
      {/* CSS Styling */}
      <style>
        {`
          .custom-btn:hover {
            background-color: white !important;
            color: black !important;
            border: 2px solid black;
          }
          @media (max-width: 991px) {
            .navbar-collapse {
              text-align: center;
            }
            .navbar-nav {
              flex-direction: column;
            }
            .navbar-nav .nav-item {
              margin-bottom: 10px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ResponsiveNavBar;
