import React, { useEffect,useState } from "react";
import { Card, Button, Dropdown, Form, Row, Col,Container,Spinner } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import VerticalNavBar from './Vertical'
import CustomModal from '../component/StrategyModal'
import CustomTabsWithInput from '../component/TabcreateStrategy'
import CardWithDropdowns from '../component/Customdropmenu'
import HeaderText from '../component/HeaderText';
import CustomorderCard from '../component/OrderCard'
import CustomCardLayout from '../component/CustomCardLayout'
import IntradayPos from '../component/IntradayPos'
import { useDispatch, useSelector } from 'react-redux';
import { setProgramStrategy} from "../redux/slice/createStrategySlice"
import axios from 'axios';
import { toast } from 'react-toastify';

function StrategyLoad() {  const dispatch = useDispatch();
  const programStrategy = useSelector((state) => state.programStrategy);
  const { terminal_source,strategy_source,segment,symbols,run_name,entry_when,exit_when} = useSelector((state) => state.programStrategy);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return; // Prevent multiple submissions

    try {
      if ( terminal_source === '')
      {
        toast.error("Please select Termninal Source to Trade");
        return ;
      }
      if (run_name === '')
      {
        toast.error("run_name not empty");return ;
      }
      if ( entry_when === "Not Selected")
      {
        toast.error("Please Select Entry Conditions for trading");return ;
      }
      if ( exit_when === "Not Selected")
      {
        toast.error("Please Select Exit Conditions for trading");return ;
      }
      if ( symbols === '')
      {
        toast.error("Please select Symbols to Trade");return ;
      }
      if (strategy_source === '')
      {
        toast.error("Please choose Strategy Source");return ;
      }
      if (segment === '')
      {
        toast.error("Please Select Segment");return ;
      }
      setLoading(true);
      const token = localStorage.getItem("Token");

      const response = await axios.post(
        "https://algocranes.com/AddNewRunProgram",
        programStrategy,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("✅ Strategy saved successfully!");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(<div style={{ whiteSpace: "normal", width: 300, wordWrap: "break-word" }}>{error.response.data.message}</div>
         );
        } else if (error.response.status === 401) {
          toast.error("⛔ Unauthorized! Please login again.");
        } else {
          toast.error(`❌ Unexpected error: ${error.response.status}`);
        }
      } else {
        toast.error("❌ Network error! Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      {/* Loading Overlay */}
      {loading && (
        <div className="loading-overlay">
          <Spinner animation="border" variant="light" />
        </div>
      )}

      <VerticalNavBar />
      <Container style={{ marginTop: 80, marginBottom: 100 }}>
        <h2 style={{ justifyContent: "center" }}>Create Order</h2>

        <CustomTabsWithInput />
        <CardWithDropdowns />
        <CustomorderCard />
        <CustomCardLayout />
        <IntradayPos />

        <Form onSubmit={handleSubmit}>
          <Card className="align-items-center mt-2 ml-1">
            <Button
              variant="success"
              className="edit-button align-center"
              style={{ width: 200, height: 50 }}
              type="submit"
              disabled={loading} // Disable button when loading
            >
              {loading ? <Spinner size="sm" animation="border" /> : "Submit"}
            </Button>
          </Card>
        </Form>
      </Container>

      {/* CSS for Transparent Loading Overlay */}
      <style jsx>{`
        .loading-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
        }
      `}</style>
    </div>
  );
}

export default StrategyLoad