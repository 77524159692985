import React, { useEffect,useState } from "react";
import { Card, Button, Container, Row, Col,Modal,Form } from "react-bootstrap";
import { FaEdit } from "react-icons/fa"; // React Icons for Edit Icon
import "../css/Tabcs.css"; // Custom CSS for the design
import { toast } from 'react-toastify';
import Createapi from '../services/CreateApi';
import { useDispatch, useSelector } from "react-redux";
import { setEntryWhen, setExitWhen } from "../redux/slice/createStrategySlice"; // Adjust path if needed

const CustomorderCard = () => {
  const dispatch = useDispatch();
  const { entry_when, exit_when } = useSelector((state) => state.programStrategy); // Get values from Redux store

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [currentEditType, setCurrentEditType] = useState(""); // "entry" or "exit"
  const [selectedValue, setSelectedValue] = useState(""); // Stores selected dropdown value

  const [dropdownOptions, setDropdownOptions] = useState([]); // Stores fetched strategy names

  // Fetch strategy parameters from API
  const fetchStrategyParams = async () => {
    try {
      const token = localStorage.getItem("Token");
      const response = await Createapi("/GetStrategyNames", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const strategyNames = response.data.data || []; // Handle potential null data
      setDropdownOptions(strategyNames); // Update dropdown options
    } catch (err) {
      console.error("Error fetching Strategy Names", err);
      toast.error("Failed to load Strategy Names"); // Show error message
    }
  };

  // Call API when component mounts
  useEffect(() => {
    fetchStrategyParams();
  }, []);

  const handleShowModal = (title, type) => {
    setModalTitle(title);
    setCurrentEditType(type);
    setShowModal(true);
  };

  const handleSave = () => {
    if (!selectedValue) {
      toast.warning("Please select a value before saving.");
      return;
    }

    // Show confirmation alert before saving
    const confirmSave = window.confirm(`Are you sure you want to save "${selectedValue}" as the ${currentEditType} condition?`);
    if (!confirmSave) return;

    if (currentEditType === "entry") {
      dispatch(setEntryWhen(selectedValue)); // Save to Redux
    } else if (currentEditType === "exit") {
      dispatch(setExitWhen(selectedValue)); // Save to Redux
    }

    setShowModal(false);
    toast.success(`${currentEditType} condition saved successfully!`);
  };

  return (
    <>
      {/* Entry Conditions Card */}
      <Card className="p-2 custom-card">
        <Row className="mb-1">
          <Col md={5} className="d-flex">
            <span className="text-yellow">Entry Conditions :</span>
          </Col>
        </Row>
        <Row>
          <Col md={5} className="d-flex">
            <span className="text-red">Entry When ?</span>
          </Col>
          <Col md={5} className="d-flex">
            <span className="text-red">{entry_when}</span> {/* Show Redux value */}
          </Col>
          <Col style={{ marginRight: 0 }}>
            <Button
              variant="primary"
              className="edit-button"
              onClick={() => handleShowModal("Edit Entry Condition", "entry")}
            >
              <FaEdit /> Edit
            </Button>
          </Col>
        </Row>
      </Card>

      {/* Exit Conditions Card */}
      <Card className="p-2 custom-card">
        <Row className="mb-1">
          <Col md={5} className="d-flex">
            <span className="mx-1 text-red1">Exit Conditions :</span>
          </Col>
        </Row>
        <Row>
          <Col md={5} className="d-flex">
            <span className="mx-1 text-red">Exit When ?</span>
          </Col>
          <Col md={5} className="d-flex">
            <span className="mx-1 text-red">{exit_when}</span> {/* Show Redux value */}
          </Col>
          <Col style={{ marginRight: 0 }}>
            <Button
              variant="primary"
              className="mx-1 edit-button"
              onClick={() => handleShowModal("Edit Exit Condition", "exit")}
            >
              <FaEdit /> Edit
            </Button>
          </Col>
        </Row>
      </Card>

      {/* Modal Component */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Select a Condition</Form.Label>
            <Form.Select value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)}>
              <option value="">Select an option</option>
              {dropdownOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </Form.Select>
          </Form.Group>

          <p className="mt-2 text-center">Data mapped with array</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
          <Button variant="primary" onClick={handleSave}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


export default CustomorderCard;
