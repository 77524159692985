import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import "../css/tooltipstyle.css";
import {
  FaPlay,
  FaEdit,
  FaTrash,
  FaFileAlt,
  FaInfo,
  FaPause,
} from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import TradingReport from "../pages/Profitloss";

const MyTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isPLLoading, setIsPLLoading] = useState(false); // Track P/L report loading
  const sadata = [];
  const sampleData = [
    { date: "2024-03-01", symbol: "AAPL", entry: 150, exit: 160,exitdate: "2024-03-01", profit: 10 },
    { date: "2024-03-02", symbol: "TSLA", entry: 700, exit: 680, exitdate: "2024-03-02", profit: -20 },
    { date: "2024-03-03", symbol: "GOOGL", entry: 2800, exit: 2900, exitdate: "2024-03-04", profit: 100 },
    { date: "2024-03-04", symbol: "MSFT", entry: 300, exit: 310,exitdate: "2024-03-05", profit: 10 },
    { date: "2024-03-05", symbol: "NFLX", entry: 500, exit: 480,exitdate: "2024-03-05", profit: -20 },
    { date: "2024-03-01", symbol: "AAPL", entry: 150, exit: 160, exitdate: "2024-03-01",profit: 10 },
    { date: "2024-03-02", symbol: "TSLA", entry: 700, exit: 680,exitdate: "2024-03-02",  profit: -20 },
    { date: "2024-03-03", symbol: "GOOGL", entry: 2800, exit: 2900,exitdate: "2024-03-03", profit: 100 },
    { date: "2024-03-04", symbol: "MSFT", entry: 300, exit: 310, exitdate: "2024-03-04",profit: 10 },
    { date: "2024-03-05", symbol: "NFLX", entry: 500, exit: 480,exitdate: "2024-03-05",  profit: -20 },
  ];
  const fetchRunningPrograms = async () => {
    setLoading(true);
    setError(null);
    setSelectedReport(sadata);
    const token = localStorage.getItem("Token");
    if (!token) {
      console.error("JWT Token is missing!");
      toast.error("Authentication failed! Please login again.");
      setError("JWT Token missing");
      setLoading(false);
      return;
    }

    try {
      console.log("Fetching data from API...");
      const response = await axios.get(
        "https://algocranes.com/GetProgramsList",       
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data?.status && response.data.statuscode === 200) {
        console.log(response.data.data);
        setData(response.data.data); // 🔥 Extract the `data` array
      } else {
        console.error("Unexpected API response format:", response.data);
        toast.error("Invalid API response format.");
      }
    } catch (error) {
      console.error(
        "Error fetching strategies:",
        error.response?.data || error.message
      );
      toast.error("Failed to fetch running programs .");
      setError(error.response?.data || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async (program) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete "${program.run_name}"?`
    );

    if (!confirmDelete) return; // If user cancels, stop execution

    try {
      const response = await axios.post(
        "https://algocranes.com/UpdateDeleteStatus",
        { RunName: program.run_name, delete_on: true }, // Update delete_on to true
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        }
      );

      if (response.data?.statuscode === 200) {
        setData((prevData) =>
          prevData.map((p) =>
            p.run_name === program.run_name ? { ...p, delete_on: true } : p
          )
        );
        toast.success(`"${program.run_name}" deleted successfully!`);
      } else {
        toast.error("Failed to delete the program.");
      }
    } catch (error) {
      console.error("Error deleting program:", error);
      toast.error("Error deleting the program.");
    }
  };
  const toggleAlgo = async (program) => {
    const newAlgoState = program.algo_on === "true" ? "false" : "true"; // Toggle value

    try {
      const response = await axios.post(
        "https://algocranes.com/UpdateAlgoStatus",
        { RunName: program.run_name, algo_on: newAlgoState },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        }
      );

      if (response.data?.statuscode === 200) {
        setData((prevData) =>
          prevData.map((p) =>
            p.run_name === program.run_name
              ? { ...p, algo_on: newAlgoState }
              : p
          )
        );
        toast.success(
          `Algo ${newAlgoState === "true" ? "ON" : "OFF"} Successfully!`
        );
      } else {
        toast.error("Failed to update algo state.");
      }
    } catch (error) {
      console.error("Error updating algo status:", error);
      toast.error("Error updating algo.");
    }
  };
  useEffect(() => {
    fetchRunningPrograms();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <Spinner animation="border" variant="primary" />
        <p>Loading...</p>
      </div>
    );
  }
  const handlePLReportClick = async (row) => {
    setIsPLLoading(true); // Start loading effect  
    const token = localStorage.getItem("Token");
    if (!token) {     
      toast.error("Authentication failed! Please login again.");
      setError("JWT Token missing");
      setIsPLLoading(false);
      return;
    }
      try {
       // alert("Fetching data from API...");
        const response = await axios.get("https://algocranes.com/Getplreport", {
          params: { name: row.run_name }, // Send data as query parameters
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          },
      });
        if (response.data?.status && response.data.statuscode === 200) {
       //   console.log(response.data.data);         
          setSelectedReport(response.data.data);
        } else {
          setSelectedReport(sampleData);
        //  console.error("Unexpected API response format:", response.data);
          toast.error("Invalid API response format.");
        }
      } catch (error) {
       
        console.error(
          "Error fetching strategies:",
          error.response?.data || error.message
        );
        toast.error(error.message);
        setError(error.response?.data || "Error fetching data");
      } finally {
        setIsPLLoading(false); // Stop loading effect after 2 seconds (simulated)
        setShowModal(true);
       
      }
  }
   
    
    
   
  if (!data || data.length === 0) {
    return <p className="no-data">No Running Programs</p>;
  }
  return (
    <>
     {isPLLoading && (
        <div className="loading-overlay">
          <Spinner animation="border" variant="light" />
          <p>Loading P/L Report...</p>
        </div>
      )}
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Run Name</th>
          <th>Order Type</th>
          <th>Entry When</th>
          <th>Exit When</th>
          <th>Terminal Source</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => {
          // Extract hidden fields for the tooltip
          const hiddenKeys = Object.keys(row).filter(
            (key) =>
              ![
                "run_name",
                "ordertype",
                "entry_when",
                "exit_when",
                "terminal_source",
              ].includes(key)
          );

          return (
            <tr key={index}>
              <td>{row.run_name}</td>
              <td>{row.ordertype}</td>
              <td>{row.entry_when}</td>
              <td>{row.exit_when}</td>
              <td>{row.terminal_source}</td>
              <td className="button-container">
                {/* Tooltip Button */}
                <OverlayTrigger
                  placement={window.innerWidth < 768 ? "bottom" : "auto"}
                  overlay={
                    <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                      <div className="tooltip-content">
                        {hiddenKeys.map((key) => (
                          <div key={key} className="tooltip-item">
                            <strong>{key}:</strong> {row[key]}
                          </div>
                        ))}
                      </div>
                    </Tooltip>
                  }
                >
                  <Button variant="outline-info" className="icon-button">
                    <FaInfo />
                  </Button>
                  
                </OverlayTrigger>

                {/* Action Buttons */}
                <div className="icon-buttons">
                    <OverlayTrigger
                     placement={window.innerWidth < 768 ? "bottom" : "auto"}
                     overlay={
                       <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                         <div className="tooltip-content">
                           Start/Stop Algo
                         </div>
                       </Tooltip>
                     }
                    >
                    <Button
                    variant={
                      row.algo_on === "true"
                        ? "outline-danger"
                        : "outline-success"
                    }
                    className="me-2"
                    onClick={() => toggleAlgo(row)}
                  >
                    {row.algo_on === "true" ? <FaPause /> : <FaPlay />}
                  </Button>
                    </OverlayTrigger>
                 
                   <OverlayTrigger
                   placement={window.innerWidth < 768 ? "bottom" : "auto"}
                   overlay={
                     <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                       <div className="tooltip-content">
                        Delete this program
                       </div>
                     </Tooltip>
                   }
                   >
                   <Button
                    variant="outline-danger"
                    className="me-2"
                    onClick={() => handleDelete(row)}
                  >
                    <FaTrash />
                  </Button>
                    </OverlayTrigger>     
                  
                  <OverlayTrigger
                  placement={window.innerWidth < 768 ? "bottom" : "auto"}
                  overlay={
                    <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                      <div className="tooltip-content">
                       click to View P/L Report
                      </div>
                    </Tooltip>
                  }
                  >
                  <Button variant="outline-success" 
                   onClick={() => handlePLReportClick(row)}
                  >
                    <FaFileAlt /> P/L
                  </Button>
                  </OverlayTrigger>
                  
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
    <TradingReport show={showModal} onHide={() => setShowModal(false)} reportData={selectedReport}  />
    </>
  );
};

export default MyTable;
