import React, { useEffect,useState } from "react";
import { Tabs, Tab, Card, Form, Row, Col, Button,Dropdown,Spinner  } from "react-bootstrap";
import "../css/Tabcs.css"; // Custom CSS for the design
import { useDispatch, useSelector } from "react-redux";
import { setIntraPos, setDailyLoss, setDailyTarget } from "../redux/slice/createStrategySlice"; // Adjust path if needed

function IntradayPos() {
  
  const dispatch = useDispatch();
  const { intra_pos, daily_loss, daily_target } = useSelector((state) => state.programStrategy);
  
  return (
    <>
    
      <Row>
        {/* Card 1 - Intraday/Positional Selection */}
        <Col md={6}>
          <Card className="p-2 custom-card">
            <Row className="mb-1">
              <Col md={5} className="d-flex">
                <span className="text-red">Transactional Expiry Details:</span>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="d-flex mt-2">
                <span>Intraday / Positional</span>
              </Col>
              <Col md={6} className="d-flex">
                <Tabs
                  id="custom-tabs"
                  activeKey={intra_pos}
                  onSelect={(k) => dispatch(setIntraPos(k))}
                  className="mb-1 custom-tabs"
                >
                  <Tab eventKey="Intraday" title="Intraday" />
                  <Tab eventKey="Positional" title="Positional" />
                </Tabs>
              </Col>
            </Row>
          </Card>
        </Col>

        {/* Card 2 - Daily Stoploss & Target Inputs */}
        <Col md={6}>
          <Card className="p-2 custom-card">
            <Row className="mb-2">
              <Col md={5} className="d-flex">
                <span>Daily Stoploss</span>
              </Col>
              <Col md={5} className="d-flex">
                <Form.Control
                  type="number"
                  placeholder="Enter Daily Stoploss"
                  value={daily_loss}
                  onChange={(e) => dispatch(setDailyLoss(e.target.value))}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={5} className="d-flex">
                <span>Daily Target</span>
              </Col>
              <Col md={5} className="d-flex">
                <Form.Control
                  type="number"
                  placeholder="Enter Daily Target"
                  value={daily_target}
                  onChange={(e) => dispatch(setDailyTarget(e.target.value))}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default IntradayPos;
