import React, {  useEffect } from "react";
import { useSelector,useDispatch } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import Loader from "../component/Loading";
import { checkToken } from "../redux/slice/authSlice";
const ProtectedRoute = ({ component: Component, ...rest }) => {
  
  const dispatch = useDispatch();
  const { isAuthenticated, error } = useSelector((state) => state.auth);
  let isAuthen = localStorage.getItem("isauth");
  const loading = useSelector((state) => state.auth.loading); // Assuming loading state exists

  useEffect(() => {
    if (!isAuthenticated) {
      // Dispatch the checkToken action to validate the token
      dispatch(checkToken()).finally(() => {
        
      });
    } else {
      // If no access token is found, consider it initialized
      
    }
  }, [dispatch,error,isAuthenticated]);

  // New state to check if the authentication status is initialized
 
  // Show loading until the token check is completed
  

  // If not authenticated, redirect to login page
 
  // If not authenticated, redirect to login page
 
  if (!isAuthen) {
    return <Navigate to="/login" />;
  }
  return (
   
    <Outlet/>
    
  
  );
};

export default ProtectedRoute;