import React, { useState, useEffect } from "react";
import VerticalNavBar from "./Vertical";
import { Card, Button, Table, Container, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../css/StyHome.css";
import { FaInfo, FaPlay, FaTrash, FaFileAlt } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

function Home() {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalProfit, setTotalProfit] = useState(0); // ✅ Total Profit State

  // 📌 Fetch Open Orders API Every 30 Seconds
  useEffect(() => {
    fetchRunningPrograms();
    const interval = setInterval(fetchRunningPrograms, 10000); // Refresh every 30 sec
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const fetchRunningPrograms = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem("Token");
    if (!token) {
      console.error("JWT Token is missing!");
      toast.error("Authentication failed! Please login again.");
      setError("JWT Token missing");
      setLoading(false);
      return;
    }

    try {
      console.log("Fetching data from API...");
      const response = await axios.get("https://algocranes.com/getOpenorders", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data?.status && response.data.statuscode === 200) {
        console.log(response.data.data);
        setOrders(response.data.data); // 🔥 Extract the `data` array

        // ✅ Calculate Cumulative Profit
        const total = response.data.data.reduce(
          (sum, order) => sum + parseFloat(order.op_profit || 0),
          0
        );
        setTotalProfit(total);
      } else {
        console.error("Unexpected API response format:", response.data);
        toast.error("Invalid API response format.");
      }
    } catch (error) {
      console.error("Error fetching strategies:", error.response?.data || error.message);
      toast.error("Failed to fetch running programs.");
      setError(error.response?.data || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  // 📌 Handle Info Button Click (Open Modal)
  const handleInfoClick = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  return (
    <div>
      <VerticalNavBar />
      <Container style={{ marginTop: 120 }}>
        <div>
          <div className="container mt-3">
            {/* ✅ Top Profit Section */}
            <div className="d-flex justify-content-between p-3 border rounded">
              <h5>
                Total Profit in $: <span className="profit-box">0</span>
              </h5>
              <h5>
                Total Profit in ₹:{" "}
                <span
                  className="profit-box"
                  style={{
                    color: totalProfit < 0 ? "red" : "green", // ✅ Show in Red if Negative
                  }}
                >
                  {totalProfit.toFixed(2)}
                </span>
              </h5>
            </div>

            <h5 className="mt-3">List Of Opening Orders</h5>
            {/* ✅ Orders Table */}
            <div className="table-responsive">
              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Run Name</th>
                    <th>Trading Symbol</th>
                    <th>Buy/SELL</th>
                    <th>Entry Price</th>
                    <th>Market Price</th>
                    <th>Profit</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) => (
                    <tr key={index}>
                      <td>{order.runname}</td>
                      <td>{order.op_tradingsymbol}</td>
                      <td>{order.op_tradetype}</td>
                      <td>{order.op_entryprice}</td>
                      <td>{order.op_ltp}</td>
                      <td
                        style={{
                          color: order.op_profit < 0 ? "red" : "green", // ✅ Profit Coloring
                          fontWeight: "bold",
                        }}
                      >
                        {order.op_profit}
                      </td>
                      <td>
                        {/* Info Button */}
                        <OverlayTrigger placement="top" overlay={<Tooltip>More Info</Tooltip>}>
                          <Button
                            variant="outline-info"
                            className="me-2"
                            onClick={() => handleInfoClick(order)}
                          >
                            <FaInfo />
                          </Button>
                        </OverlayTrigger>

                        {/* Play Button */}
                        <OverlayTrigger placement="top" overlay={<Tooltip>Start Algo</Tooltip>}>
                          <Button variant="outline-success" className="me-2">
                            <FaPlay />
                          </Button>
                        </OverlayTrigger>

                        {/* Delete Button */}
                        <OverlayTrigger placement="top" overlay={<Tooltip>Delete Order</Tooltip>}>
                          <Button variant="outline-danger" className="me-2">
                            <FaTrash />
                          </Button>
                        </OverlayTrigger>

                        {/* Report Button */}
                        <OverlayTrigger placement="top" overlay={<Tooltip>View P/L Report</Tooltip>}>
                          <Button variant="outline-success">
                            <FaFileAlt /> P/L
                          </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            {/* ✅ Info Modal */}
            {selectedOrder && (
              <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Order Details - {selectedOrder.run_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <pre>{JSON.stringify(selectedOrder, null, 2)}</pre>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Home;
