import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { useTable, useFilters } from "react-table";
import {
  FaChartLine,
  FaArrowDown,
  FaArrowUp,
  FaExclamationCircle,
} from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";

const TradingReport = ({ show, onHide, reportData }) => {
  // Table Columns
  const columns = React.useMemo(
    () => [
      { Header: "Date", accessor: "date" },
      { Header: "Symbol", accessor: "symbol" },
      { Header: "Entry Price", accessor: "entry" },
      { Header: "Exit Price", accessor: "exit" },
      { Header: "Exit Date", accessor: "exitdate" },
      {
        Header: "Profit (₹)",
        accessor: "profit",
        Cell: ({ value }) => (
          <span
            className={
              value >= 0 ? "text-success fw-bold" : "text-danger fw-bold"
            }
          >
            {value}
          </span>
        ),
      },
    ],
    []
  );

  // React Table Hook
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: reportData }, useFilters);
  if (!reportData || reportData.length === 0) {
    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Profit/Loss Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>No data available.</Modal.Body>
      </Modal>
    );
  }
  // Calculate Stats
  const cumulativeProfit = reportData
  .map(trade => Number(trade.profit) || 0) // Convert to number, default to 0 if invalid
  .reduce((sum, profit) => sum + profit, 0); // Sum up all profits

  const maxDrawdown = Math.min(...reportData.filter((t) => t.profit < 0).map((t) => t.profit), 0);

  const countMaxConsecutive = (arr, condition) => {
    let maxCount = 0, currentCount = 0;
    for (const t of arr) {
      if (condition(t)) {
        currentCount++;
        maxCount = Math.max(maxCount, currentCount);
      } else {
        currentCount = 0;
      }
    }
    return maxCount;
  };
  
  // Calculate Consecutive Wins (Trades with Profit > 0)
  const consecutiveWins = countMaxConsecutive(reportData, (t) => t.profit > 0);
  
  // Calculate Consecutive Losses (Trades with Profit < 0)
  const consecutiveLosses = countMaxConsecutive(reportData, (t) => t.profit < 0);
  // Graph Data
  const graphData = reportData.map((item) => ({
    date: item.date,
    profit: item.profit,
  }));

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>P/L Report </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mt-4">
            {/* Stats Cards */}
            <div className="row g-3 mb-4">
              <div className="col-md-3">
                <div className="card shadow-lg text-white bg-success">
                  <div className="card-body text-center">
                    <FaChartLine size={30} className="mb-2" />
                    <h5>Cumulative Profit</h5>
                    <h4>₹ {cumulativeProfit}</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card shadow-lg text-white bg-danger">
                  <div className="card-body text-center">
                    <FaArrowDown size={30} className="mb-2" />
                    <h5>Max Drawdown</h5>
                    <h4>₹ {maxDrawdown}</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card shadow-lg text-white bg-primary">
                  <div className="card-body text-center">
                    <FaArrowUp size={30} className="mb-2" />
                    <h5>Consecutive Wins</h5>
                    <h4>{consecutiveWins}</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card shadow-lg text-white bg-warning">
                  <div className="card-body text-center">
                    <FaExclamationCircle size={30} className="mb-2" />
                    <h5>Consecutive Losses</h5>
                    <h4>{consecutiveLosses}</h4>
                  </div>
                </div>
              </div>
            </div>

            {/* Graph Section */}
            <div className="card shadow-lg p-3 mb-4 bg-white rounded">
              <h4 className="text-center text-primary">Trading Performance</h4>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={graphData}>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="profit"
                    stroke="#007bff"
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>

            {/* Table Section with Horizontal Scroll */}
            <div className="card shadow-lg p-3 bg-white rounded">
              <h4 className="text-center text-primary mb-3">Trade History</h4>
              <div
                className="table-responsive"
                style={{ maxHeight: "400px", overflowY: "auto" }}
              >
                <table
                  {...getTableProps()}
                  className="table table-hover table-bordered"
                >
                  <thead className="table-dark">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TradingReport;
