import React,{useEffect} from "react";
import { Card, Button, Dropdown, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSegment,
  updateExpiry,
  updatePremiumSelector,
  updateStrike,
  updateStrikePrice,
  updateOptionType,
  updateQty,
  updateTradeType,
  updateSLMode,
  updateStopLoss,
  updateTPMode,
  updateTarget,
} from "../redux/slice/createStrategySlice"; // Update the path to your actions

const CustomCardLayout = () => {
  const dispatch = useDispatch();

  // Fetch state from the programStrategy reducer
  const {
    op_segment,
    op_expiry,
    op_premiumselector,
    op_strike,
    op_strikeprice,
    op_optiontype,
    op_qty,
    op_tradetype,
    op_slmode,
    op_stoploss,
    op_tpmode,
    op_target,
  } = useSelector((state) => state.programStrategy);
  useEffect(() => {
    console.log("Updated programStrategy state:", {
      op_segment,
      op_expiry,
      op_premiumselector,
      op_strike,
      op_strikeprice,
      op_optiontype,
      op_qty,
      op_tradetype,
      op_slmode,
      op_stoploss,
      op_tpmode,
      op_target,
    });
  }, [
    op_segment,
    op_expiry,
    op_premiumselector,
    op_strike,
    op_strikeprice,
    op_optiontype,
    op_qty,
    op_tradetype,
    op_slmode,
    op_stoploss,
    op_tpmode,
    op_target,
  ]);
  // Toggle CE/PE and BUY/SELL states
  const toggleOptionType = () => {
    const newOptionType = op_optiontype === "CE" ? "PE" : "CE";
    dispatch(updateOptionType(newOptionType));
  };

  const toggleTradeType = () => {
    const newTradeType = op_tradetype === "BUY" ? "SELL" : "BUY";
    dispatch(updateTradeType(newTradeType));
  };

  // Handle dropdown changes
  const handleDropdownChange = (field, value) => {
    switch (field) {
      case "segment":
        dispatch(updateSegment(value));
        break;
      case "expiry":
        dispatch(updateExpiry(value));
        break;
      case "premiumSelector":
        dispatch(updatePremiumSelector(value));
        break;
      case "strike":
        dispatch(updateStrike(value));
        break;
      case "slMode":
        dispatch(updateSLMode(value));
        break;
      case "tpMode":
        dispatch(updateTPMode(value));
        break;
      default:
        break;
    }
  };

  // Handle input changes
  const handleInputChange = (field, value) => {
    switch (field) {
      case "strikePrice":
        dispatch(updateStrikePrice(value));
        break;
      case "qty":
        dispatch(updateQty(value));
        break;
      case "stopLoss":
        dispatch(updateStopLoss(value));
        break;
      case "target":
        dispatch(updateTarget(value));
        break;
      default:
        break;
    }
  };

  return (
    <div className="container">
      <Card className="mb-2">
        <Card.Body>
          <div className="scrollable-container">
            <Row className="g-2 align-items-center">
              {/* Segment Dropdown */}
              <Col xs="auto" className="custom-element">
                <div className="element-container">
                  <Form.Text className="text-muted">Segment</Form.Text>
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" className="dropdown-large dropdown-custom">
                      {op_segment}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {["Futures", "Options", "Equity", "Forex", "Crypto"].map((option, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => handleDropdownChange("segment", option)}
                        >
                          {option}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>

              {/* Expiry Dropdown (Conditional) */}
              {op_segment === "Options" && (
                <Col xs="auto" className="custom-element">
                  <div className="element-container">
                    <Form.Text className="text-muted">Expiry</Form.Text>
                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" className="dropdown-large dropdown-custom">
                        {op_expiry}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {["Current Week", "Next Week", "Current Month", "Next Month"].map((option, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => handleDropdownChange("expiry", option)}
                          >
                            {option}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              )}

              {/* Premium Selector Dropdown (Conditional) "Premium Greater", "Premium Lesser" */}
              {op_segment === "Options" && (
                <Col xs="auto" className="custom-element">
                  <div className="element-container">
                    <Form.Text className="text-muted">Premium Selector</Form.Text>
                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" className="dropdown-large dropdown-custom">
                        {op_premiumselector}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>   
                        {["Spot Based", "Strike Price"].map((option, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => handleDropdownChange("premiumSelector", option)}
                          >
                            {option}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              )}

              {/* Strike Dropdown (Conditional) */}
              {op_segment === "Options" && op_premiumselector !== "Strike Price" && (
                <Col xs="auto" className="custom-element">
                  <div className="element-container">
                    <Form.Text className="text-muted">Strike</Form.Text>
                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" className="dropdown-small dropdown-custom">
                        {op_strike}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {["ITM5", "ITM4", "ITM3", "ITM2", "ITM1", "ATM", "OTM1", "OTM2", "OTM3", "OTM4", "OTM5"].map((option, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => handleDropdownChange("strike", option)}
                          >
                            {option}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              )}

              {/* Strike Price Input (Conditional) */}
              {op_segment === "Options" && op_premiumselector === "Strike Price" && (
                <Col xs="auto" className="custom-element">
                  <div className="element-container">
                    <Form.Text className="text-muted">Strike Price</Form.Text>
                    <Form.Control
                      type="text"
                      placeholder="like 25900.00"
                      className="input-small"
                      value={op_strikeprice}
                      onChange={(e) => handleInputChange("strikePrice", e.target.value)}
                    />
                  </div>
                </Col>
              )}

              {/* CE/PE Toggle Button */}
              <Col xs="auto" className="custom-element">
                <div className="element-container">
                 
                  <Button
                    onClick={toggleOptionType}
                    className={`toggle-button ${op_optiontype === "CE" ? "untoggled" : "toggled"}`}
                  >
                    {op_optiontype}
                  </Button>
                </div>
              </Col>

              {/* Lot in Qty Input */}
              <Col xs="auto" className="custom-element">
                <div className="element-container">
                  <Form.Text className="text-muted">Lot in qty</Form.Text>
                  <Form.Control
                    type="text"
                    placeholder="Not LotSize"                     
                    value={op_qty}
                    className="input-small"
                    onChange={(e) => handleInputChange("qty", e.target.value)}
                  />
                </div>
              </Col>

              {/* BUY/SELL Toggle Button */}
              <Col xs="auto" className="custom-element">
                <div className="element-container">
                
                  <Button
                    onClick={toggleTradeType}
                    className={`toggle-button ${op_tradetype === "BUY" ? "untoggled" : "toggled"}`}
                  >
                    {op_tradetype}
                  </Button>
                </div>
              </Col>

              {/* SL Mode Dropdown */}
              <Col xs="auto" className="custom-element">
                <div className="element-container">
                  <Form.Text className="text-muted">SL Mode</Form.Text>
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" className="dropdown-smallx dropdown-custom">
                      {op_slmode}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {["₹", "%", "Pts"].map((option, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => handleDropdownChange("slMode", option)}
                        >
                          {option}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>

              {/* Stop Loss Input */}
              <Col xs="auto" className="custom-element">
                <div className="element-container">
                  <Form.Text className="text-muted">Stop Loss</Form.Text>
                  <Form.Control
                    type="text"
                    placeholder="SL Value"
                    value={op_stoploss}
                    onChange={(e) => handleInputChange("stopLoss", e.target.value)}
                  />
                </div>
              </Col>

              {/* TP Mode Dropdown */}
              <Col xs="auto" className="custom-element">
                <div className="element-container">
                  <Form.Text className="text-muted">TP Mode</Form.Text>
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" className="dropdown-smallx dropdown-custom">
                      {op_tpmode}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {["₹", "%", "Pts"].map((option, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => handleDropdownChange("tpMode", option)}
                        >
                          {option}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>

              {/* Target Input */}
              <Col xs="auto" className="custom-element">
                <div className="element-container">
                  <Form.Text className="text-muted">Target</Form.Text>
                  <Form.Control
                    type="text"
                    placeholder="TP Value"
                    value={op_target}
                    onChange={(e) => handleInputChange("target", e.target.value)}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CustomCardLayout;