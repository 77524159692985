import React, { useState, useEffect } from 'react';
import { Card, Dropdown, Row, Col } from 'react-bootstrap';
import axios from 'axios'; // For making API requests
import { useDispatch, useSelector } from 'react-redux';
import {updateField } from "../redux/slice/createStrategySlice"
import Createapi from '../services/CreateApi';
import { toast } from 'react-toastify';

const CardWithDropdowns = () => {
  const dispatch = useDispatch();
  const { terminal_source,strategy_source,segment,symbols} = useSelector((state) => state.programStrategy);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateField({ field: name, value })); // Dispatch action to update state
  };
  const token = localStorage.getItem('Token');
  let strategyparams = [] ; 
  const fetchTerminalNames = async () => {
    try {
      const response = await Createapi('/TerminalName', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const terminalNames = response.data.data || []; // Make sure to handle potential null data
      setDropdown1Items(terminalNames); // Update state with fetched terminal names
    } catch (err) {
      console.error("Error fetching terminal names:", err);
      toast.error("Failed to load terminal names"); // Show error message
    }
  };
  const fetchstrategyparamas = async () => {
    try {
      const response = await Createapi('/GetStrategyNames', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const terminalNames = response.data.data || []; // Make sure to handle potential null data
      strategyparams = terminalNames ;       
      setDropdown2Items(terminalNames); // Update state with fetched terminal names
    } catch (err) {
      console.error("Error fetching Strategy Names", err);
      toast.error("Failed to load Strategy Names"); // Show error message
    }
  };
  const fetchallsymbols = async () => {
    try {
      const response = await Createapi('/getsymbol?segment='+segment, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const symbolsnames = response.data.data || []; // Make sure to handle potential null data
      console.log(symbolsnames);
      setDropdown4Items(symbolsnames); // Update state with fetched terminal names
    } catch (err) {
      console.error("Error fetching terminal names:", err);
      toast.error("Failed to load terminal names"); // Show error message
    }
  };
  useEffect(() => {   
      fetchTerminalNames();  
      fetchstrategyparamas();
  }, []);
  // State to store dropdown data and selected values
  const [dropdown1Items, setDropdown1Items] = useState([]);
  const [dropdown2Items, setDropdown2Items] = useState([]);
  const [dropdown3Items, setDropdown3Items] = useState([]);
  const [dropdown4Items, setDropdown4Items] = useState([]);
  const [selectedItem1, setSelectedItem1] = useState('');
  const [selectedItem2, setSelectedItem2] = useState('');
  const [selectedItem3, setSelectedItem3] = useState('');
  const [selectedItem4, setSelectedItem4] = useState('');
  
  const data3 = ["Futures","Index","Equity","Commodity", "Forex", "Crypto"];
 
  // Fetch data for the dropdowns
  useEffect(() => {
    setDropdown3Items(data3);   
    
    
  }, []);
  useEffect(() => {
    if (segment) {  // Only fetch symbols when segment is selected
        fetchallsymbols();
    }
}, [segment]);
  // Handle dropdown selection
  const handleSelect1 = (eventKey) => {
    setSelectedItem1(eventKey);
    dispatch(updateField({ field: 'terminal_source', value: eventKey }));
  };
  const handleSelect2 = (eventKey) => {
    setSelectedItem2(eventKey);
    dispatch(updateField({ field: 'strategy_source', value: eventKey }));
  };
  const handleSelect3 = (eventKey) => {
    setSelectedItem3(eventKey);
    dispatch(updateField({ field: 'segment', value: eventKey }));

  };
  const handleSelect4 = (eventKey) => {
    setSelectedItem4(eventKey);
    dispatch(updateField({ field: 'symbols', value: eventKey }));
  };

  return (
    <Card className="custom-card"> {/* Bootstrap card with padding */}
      <Card.Body>
        <Row>
          {/* Dropdown Menus */}
          <Col md={3}>
            <div className="mb-3">
              <h6>Terminal Source:</h6>
              <Dropdown onSelect={handleSelect1}
              name="terminal_source"
              value={terminal_source}
              onChange={handleInputChange}
              >                    
                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{width:200}}>
                  {selectedItem1 || 'Select an option'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {dropdown1Items.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item}>
                      {item}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>

          <Col md={3}>
            <div className="mb-3">
              <h6>Select Instrument Segment:</h6>
              <Dropdown onSelect={handleSelect3}
              name="segment"
              value={segment}
              >
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {selectedItem3 || 'Select an option'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {dropdown3Items.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item}>
                      {item}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>

          <Col md={3}>
            <div className="mb-3">
              <h6>Symbols:</h6>
              <Dropdown onSelect={handleSelect4}
              name="symbols"
              value={symbols}
              >
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {selectedItem4 || 'Select an option'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {dropdown4Items.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item}>
                      {item}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>

          <Col md={3}>
            <div className="mb-3">
              <h6>Strategy Source:</h6>
              <Dropdown onSelect={handleSelect2}
               name="strategy_source"
               value={strategy_source}
              >
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {selectedItem2 || 'Select an option'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {dropdown2Items.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item}>
                      {item}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CardWithDropdowns;
